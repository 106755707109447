import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";
import {Textarea} from "../../ui/Textarea";

import validator from 'validator'

import Spin from 'arui-feather/spin'
import CoveredUploadPanel from "../../upload/CoveredUploadPanel";
import {DEFAULT_TIMEZONE, FACELESS_AVATAR} from "../../../constants/config";
// import DatePicker from "../../ui/DatePicker";

import DatePicker from 'react-datepicker'

import Select from 'react-select'

import InputMask from 'react-input-mask'

const HANDS_OPTIONS = [
    {
        label: 'Левая',
        value: 'left'
    },
    {
        label: 'Правая',
        value: 'right'
    }
];

const HOCKEY_ROLES_OPTIONS = [
    {
        label: 'Вратарь',
        value: 'goaltender'
    },
    {
        label: 'Левый нападающий',
        value: 'left_winger'
    },
    {
        label: 'Правый нападающий',
        value: 'right_winger'
    },
    {
        label: 'Центральный нападающий',
        value: 'center_winger'
    },
    {
        label: 'Левый защитник',
        value: 'left_defenseman'
    },
    {
        label: 'Правый защитник',
        value: 'right_defenseman'
    }
];


export default function UpdateUserForm(props) {
    const [email, setEmail] = useState(props.email == undefined ? '' : props.email);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [avatar, setAvatar] = useState(props.avatar);
    const [birthdayTimestamp, setBirthdayTimestamp] = useState(props.birthdayTimestamp);

    const [height, setHeight] = useState(props.height == undefined ? '' : props.height);
    const [weight, setWeight] = useState(props.weight == undefined ? '' : props.weight);
    const [hand, setHand] = useState(props.hand == undefined ? '' : props.hand);
    const [hockeyRole, setHockeyRole] = useState(props.hockeyRole == undefined ? '' : props.hockeyRole);
    const [name, setName] = useState(props.name == undefined ? '' : props.name);

    const [parentName, setParentName] = useState(props.parentName == undefined ? '' : props.parentName);
    const [parentEmail, setParentEmail] = useState(props.parentEmail == undefined ? '' : props.parentEmail);
    const [parentPhone, setParentPhone] = useState(props.parentPhone == undefined ? '' : props.parentPhone);


    const [password, setPassword] = useState(props.password == undefined ? '' : props.password);
    const [passwordConfirmation, setPasswordConfirmation] = useState(props.password == undefined ? '' : props.password);

    const ava = (avatar == undefined) ? FACELESS_AVATAR : avatar;
    console.log('ava = ', ava);

    const {
        nameVisible = false,
        emailVisible = true,
        passwordVisible = true,
        buttonText = 'Сохранить',
        loading = false,
        avatarVisible = false,
        showParentInfo = false,
        showStudentInfo = false,
        shouldShowBirthdayCalendar = true,
        onSave = () => {

        },
        canEdit = true
    } = props;

    let canSubmit =
        ((((emailVisible == true) && (passwordVisible == true) && (password != undefined) && password.length > 3 && validator.isEmail(email) == true && password == passwordConfirmation)) || (
            (emailVisible == false) && (passwordVisible == false)
        ));

    return (
        <Wrapper>

            {avatarVisible == false ? null :
                <Field>
                    <Label>
                        Аватар
                    </Label>
                    <Ava src={ava}>
                        {canEdit == false ? null :
                            <CoveredUploadPanel url={ava} onChange={url => {
                                setAvatar(url);
                            }}/>
                        }
                    </Ava>
                </Field>
            }

            {nameVisible == false ? null :
                <Field>
                    <Label>
                        Название Франчайзи
                    </Label>
                    <Input disabled={!canEdit} value={name} onChange={evt => setName(evt.target.value)}/>
                </Field>
            }

            <Field>
                <Label>
                    Имя
                </Label>
                <Input disabled={!canEdit} value={firstName} onChange={evt => setFirstName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Фамилия
                </Label>
                <Input disabled={!canEdit} value={lastName} onChange={evt => setLastName(evt.target.value)}/>
            </Field>

            <Field>
                <Label>
                    Телефон
                </Label>
                <InputMask className={'my_phone'}
                           mask="+7 (999) 999-99-99"
                           value={phone} onChange={evt =>
                    setPhone(evt.target.value)}/>
            </Field>

            {shouldShowBirthdayCalendar == false ? null :
                <Field>
                    <Label>
                        Дата рождения
                    </Label>
                    {canEdit == true ?
                        <div>
                            <DatePicker
                                dateFormat="d MMMM, yyyy"
                                selected={birthdayTimestamp == undefined ? undefined : new Date(birthdayTimestamp)}
                                onChange={newDate => {
                                    if (canEdit == false) {
                                        return;
                                    }
                                    setBirthdayTimestamp(+new Date(newDate));
                                }}
                                showYearDropdown
                                scrollableYearDropdown
                                yearDropdownItemNumber={60}
                            />
                        </div>

                        :
                        <div>
                            {moment(birthdayTimestamp).tz(DEFAULT_TIMEZONE).format('DD.MM.YYYY')}
                        </div>
                    }

                </Field>
            }


            {emailVisible == false ? null :
                <Field>
                    <Label>
                        Email
                    </Label>
                    <Input disabled={!canEdit} value={email} onChange={evt => setEmail(evt.target.value)}/>
                </Field>
            }


            {passwordVisible == false ? null :
                <Field>
                    <Label>
                        Пароль
                    </Label>
                    <Input disabled={!canEdit} value={password} onChange={evt => setPassword(evt.target.value)}/>
                </Field>
            }


            {passwordVisible == false ? null :
                <Field>
                    <Label>
                        Повторите пароль
                    </Label>
                    <Input disabled={!canEdit} value={passwordConfirmation}
                           onChange={evt => setPasswordConfirmation(evt.target.value)}/>
                </Field>
            }

            {showStudentInfo == false ? null :
                <Field>
                    <Label>
                        Вес (кг)
                    </Label>
                    <Input disabled={!canEdit} value={weight} onChange={evt => setWeight(evt.target.value)}/>
                </Field>
            }

            {showStudentInfo == false ? null :
                <Field>
                    <Label>
                        Рост (см)
                    </Label>
                    <Input disabled={!canEdit} value={height} onChange={evt => setHeight(evt.target.value)}/>
                </Field>
            }

            {showStudentInfo == false ? null :
                <Field>
                    <Label>
                        Ведущая рука
                    </Label>
                    <Select options={HANDS_OPTIONS}
                            value={(hand == undefined) ? undefined : HANDS_OPTIONS.filter(a => (a.value == hand))[0]}
                            placeholder={'Выберите ведущую руку'}
                            onChange={a => {
                                setHand(a.value);
                            }}/>
                </Field>
            }

            {showStudentInfo == false ? null :
                <Field>
                    <Label>
                        Амплуа
                    </Label>
                    <Select options={HOCKEY_ROLES_OPTIONS}
                            value={(hockeyRole == undefined) ? undefined : HOCKEY_ROLES_OPTIONS.filter(a => (a.value == hockeyRole))[0]}
                            placeholder={'Выберите амплуа'}
                            onChange={a => {
                                setHockeyRole(a.value);
                            }}/>
                </Field>
            }


            {showParentInfo == false ? null :
                <Field>
                    <Label>
                        Имя представителя (родителя)
                    </Label>
                    <Input disabled={!canEdit} value={parentName}
                           onChange={evt => setParentName(evt.target.value)}/>
                </Field>
            }

            {showParentInfo == false ? null :
                <Field>
                    <Label>
                        Email представителя (родителя)
                    </Label>
                    <Input disabled={!canEdit} value={parentEmail}
                           onChange={evt => setParentEmail(evt.target.value)}/>
                </Field>
            }

            {showParentInfo == false ? null :
                <Field>
                    <Label>
                        Телефон представителя (родителя)
                    </Label>
                    <InputMask disabled={!canEdit} className={'my_phone'}
                               mask="+7 (999) 999-99-99"
                               value={parentPhone} onChange={evt =>
                        setParentPhone(evt.target.value)}/>
                </Field>
            }


            {canEdit == false ? null :
                <Field>
                    <Button
                        style={{marginLeft: 0}}
                        disabled={!canSubmit} onClick={() => {
                        if (canSubmit == false) {
                            return;
                        }
                        let d = {
                            firstName,
                            lastName,
                            name,
                            phone,
                            avatar,
                            birthdayTimestamp,
                            parentName,
                            parentPhone,
                            parentEmail,
                            hockeyRole,
                            hand,
                            weight: +weight,
                            height: +height
                        };
                        if (password != passwordConfirmation) {
                            return;
                        }
                        if (emailVisible == true) {
                            d.email = email;
                        }
                        if (passwordVisible == true) {
                            d.password = password;
                        }
                        onSave(d);
                    }}>
                        <Spin visible={(loading)}/>
                        {loading == true ? null : buttonText}
                    </Button>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const AvaPlaceholder = styled.div`
    margin-bottom: 10px;
`;

const avaSize = 80;

const Ava = styled.div`
    display: inline-block;
    vertical-align: top;
    width: ${avaSize}px;
    height: ${avaSize}px;
    border-radius: 10px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url(${props => props.src});
`;

const DatePickerPlaceholder = styled.div`
    height: 240px;
`;
