export const DEFAULT_TIMEZONE = 'Europe/Moscow';
export const UNITPAY_ID = '223931-10204';

export const AW_API_KEY = '21d13f23-d43f-4d2d-ae1f-61a83ff79751';

export const CHATKIT_LOCATOR = 'v1:us1:e99522bb-353a-4a67-8bd9-012559461819';

export const PARSE_APP_ID = 'h1QhtsSjeoyQSa8RDQBDPvgbnI7Ix6nadHTsepwN';
export const PARSE_JS_KEY = 'Ci34OXCgbv7TuVOiUJFOmoSwULbC7JRnxvFaT1ZI';
export const PARSE_SERVER_URL = 'https://chess-prod-parse.sabir.pro/parse';

// export const OPENVIDU_SERVER_URL = 'https://openviduback2.shagi.online';
// export const OPENVIDU_SERVER_URL = 'https://kms.englishpatient.org';
export const OPENVIDU_SERVER_URL = 'https://de-fra-1.kms.sabir.pro';
export const OPENVIDU_SERVER_SECRET = 'MY_SECRET';

export const OPENVIDU_ICE_SERVERS = [
    {
        url: 'stun:stun.l.google.com:19302',
        urls: ["stun:stun.l.google.com:19302"]
    },
    {
        username: 'sabir',
        credential: 'sabir',
        url: 'turn:161.35.17.79:3478',
        urls: ["turn:161.35.17.79:3478"]
    }
];

export const FACELESS_AVATAR = 'https://www.englishpatient.org/storage/e7d099fe-653b-488e-9970-f693ab585f05.png';

export const UPLOAD_DO_ENDPOINT_URL = 'https://www.englishpatient.org/api/upload';
// export const UPLOAD_DO_ENDPOINT_URL = 'https://ruptotsu2.sabir.pro/upload';

// export const API_ENDPOINT = 'https://chess-prod-api.sabir.pro';
export const API_ENDPOINT = 'https://api.cloud1.shagi.online';

export const LOGIN_BACKGROUNR_IMAGE = 'https://www.englishpatient.org/english-patient-files/36208fa0-49a1-406a-8ac7-ecfaeb810a55.jpg';

export const WHITE_SQUARE_COLOR = '#EFEED4';
export const BLACK_SQUARE_COLOR = '#75965C';

export const WHITE_SELECTED_SQUARE_COLOR = '#F8F494';
export const BLACK_SELECTED_SQUARE_COLOR = '#BCC95A';

export const FIREBASE_CREDENTIALS = {
    apiKey: "AIzaSyCkMiS1PbE38yJ6SJ7rm-VzZBLV0pFFQaM",
    authDomain: "realtimechess-a9566.firebaseapp.com",
    databaseURL: "https://realtimechess-a9566.firebaseio.com",
    projectId: "realtimechess-a9566",
    storageBucket: "",
    messagingSenderId: "397914717078"
};

export const STARTING_FEN = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
export const WEB_APP_PUBLIC_URL = 'https://shagi.online/app';

export const PIECES_MAP = {
    'p': {
        icon: require('../assets/images/pieces/black_pawn.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bp.png',
        chessDefinition: {
            color: 'b',
            type: 'p'
        }
    },
    'k': {
        icon: require('../assets/images/pieces/black_king.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bk.png',
        chessDefinition: {
            color: 'b',
            type: 'k'
        }
    },
    'n': {
        icon: require('../assets/images/pieces/black_knight.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bn.png',
        chessDefinition: {
            color: 'b',
            type: 'n'
        }
    },
    'b': {
        icon: require('../assets/images/pieces/black_bishop.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bb.png',
        chessDefinition: {
            color: 'b',
            type: 'b'
        }
    },
    'q': {
        icon: require('../assets/images/pieces/black_queen.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/bq.png',
        chessDefinition: {
            color: 'b',
            type: 'q'
        }
    },
    'r': {
        icon: require('../assets/images/pieces/black_rook.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/br.png',
        chessDefinition: {
            color: 'b',
            type: 'r'
        }
    },

    'P': {
        icon: require('../assets/images/pieces/white_pawn.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wp.png',
        chessDefinition: {
            color: 'w',
            type: 'p'
        }
    },
    'K': {
        icon: require('../assets/images/pieces/white_king.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wk.png',
        chessDefinition: {
            color: 'w',
            type: 'k'
        }
    },
    'N': {
        icon: require('../assets/images/pieces/white_knight.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wn.png',
        chessDefinition: {
            color: 'w',
            type: 'n'
        }
    },
    'B': {
        icon: require('../assets/images/pieces/white_bishop.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wb.png',
        chessDefinition: {
            color: 'w',
            type: 'b'
        }
    },
    'Q': {
        icon: require('../assets/images/pieces/white_queen.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wq.png',
        chessDefinition: {
            color: 'w',
            type: 'q'
        }
    },
    'R': {
        icon: require('../assets/images/pieces/white_rook.svg'),
        smartIcon: 'https://images.chesscomfiles.com/chess-themes/pieces/neo/200/wr.png',
        chessDefinition: {
            color: 'w',
            type: 'r'
        }
    }
}
