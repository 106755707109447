import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {Textarea} from "../../ui/Textarea";
import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import ColorSelector from "../../chess/tools/ColorSelector";
import ChessHelper from "../../../helpers/ChessHelper";
import CommonHelper from "../../../helpers/CommonHelper";
import TagsSelector from "../../tags/panels/TagsSelector";
import ExercisesFoldersSelector from "../../exercises_folders/panels/ExercisesFoldersSelector";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import Tabs from "../../ui/Tabs";
import FinalExerciseDialogView from "../tools/FinalExerciseDialogView";
import ExerciseResultForm from "./ExerciseResultForm";

const ie = a => (a == undefined || `${a}`.trim() == '');
const dc = a => (a == undefined ? undefined : JSON.parse(JSON.stringify(a)));

export default function ExerciseForm(props) {
    let {loading, canEdit = true} = props;
    let onSave = (props.onSave == undefined) ? () => {
    } : props.onSave;
    let [formData, setFormData] = useState(
        {
            name: props.name,
            description: props.description,
            color: props.color,
            tags: (props.tags == undefined) ? [] : props.tags,
            foldersIds: (props.foldersIds == undefined) ? [] : props.foldersIds,
            pgn: (props.pgn == undefined) ? '' : props.pgn,
            fen: (props.fen == undefined) ? '' : props.fen,

            finalImageUrl: props.finalImageUrl,
            finalAudioUrl: props.finalAudioUrl,
            finalVideoUrl: props.finalVideoUrl,
            finalText: (props.finalText == undefined) ? '' : props.finalText,
            finalHtml: (props.finalHtml == undefined) ? '' : props.finalHtml
        }
    );

    useEffect(() => {
        ChessHelper.parsePgnString(formData.pgn).then(pld => {
            try {
                let nD = {...formData, fen: pld.headers.FEN};
                setFormData(nD);
            } catch (e) {
            }
        }).catch(exc => {
            console.log('can not parse PGN!');
        });
    }, [formData.pgn]);

    console.log('render: formData = ', formData);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={formData.name} autoFocus={true} onChange={evt => {
                    let newData = {...formData, name: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={formData.description} onChange={evt => {
                    let newData = {...formData, description: evt.target.value};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Теги
                </Label>
                <TagsSelector tags={formData.tags} onChange={newTags => {
                    let newData = {...formData, tags: newTags};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    Папки
                </Label>
                <ExercisesFoldersSelector foldersIds={formData.foldersIds} onChange={newIds => {
                    let newData = {...formData, foldersIds: newIds};
                    setFormData(newData);
                }}/>
            </Field>

            <Field>
                <Label>
                    PGN
                    <InfoSpan>
                        <a target={'_blank'} href={'https://lichess.org/editor'}>
                            <InfoImage src={infImg}/>
                        </a>
                    </InfoSpan>
                </Label>
                <Textarea value={formData.pgn}
                          placeholder={'Вставить PGN'}
                          style={{height: `20em`, minHeight: `20em`}}
                          onChange={evt => {
                              let newPgn = evt.target.value;
                              let newData = {...formData, pgn: newPgn};
                              setFormData(newData);
                          }}/>
            </Field>

            <Field>
                <Label>
                    FEN
                </Label>
                <Input value={formData.fen}
                       placeholder={'Вставить FEN начальной позиции'}
                       onChange={evt => {
                           let newData = {...formData, fen: evt.target.value};
                           setFormData(newData);
                       }}/>
                <div style={{textAlign: 'right'}}>
                    <BSpan onClick={() => {
                        ChessHelper.parsePgnString(formData.pgn).then(pld => {
                            let newData = {...formData, fen: pld.headers.FEN};
                            setFormData(newData);
                        });
                    }}>
                        вставить FEN из PGN
                    </BSpan>
                </div>
            </Field>

            <Field>
                <Label>
                    Цвет игрока
                </Label>
                <ColorSelector color={formData.color} onChange={newColor => {
                    let newData = {...formData, color: newColor};
                    setFormData(newData);
                }}/>
            </Field>


            <Field>
                <Label>
                    ID
                </Label>
                <span onClick={() => {
                    CommonHelper.linkTo(`/exercise/${props.id}`);
                }}>
                    {props.id}
                </span>
            </Field>

            <h3>
                Визуальный вид финальной панельки
            </h3>

            <Tabs
                tabs={[
                    {
                        label: 'Редактор',
                        content: (
                            <div>
                                <ExerciseResultForm
                                    {...formData}
                                    onSave={dd => {
                                        console.log('onSave: dd = ', dd);
                                        setFormData({...formData, ...dd})
                                    }}
                                />
                            </div>
                        )
                    },
                    {
                        label: 'Превью',
                        content: (
                            <div>
                                <FinalExerciseDialogView
                                    imageUrl={formData.finalImageUrl}
                                    text={formData.finalText}
                                    videoUrl={formData.finalVideoUrl}
                                    audioUrl={formData.finalAudioUrl}
                                    html={formData.finalHtml}
                                />
                            </div>
                        )
                    }
                ]}
            />

            {canEdit == false ? null :
                <Field>
                    <Button onClick={() => {
                        onSave(formData);
                    }}>
                        {loading == true ?
                            <Spin visible={true}/> :
                            <span>
                            Сохранить
                        </span>
                        }

                    </Button>
                </Field>
            }


        </Wrapper>
    );
}


const Wrapper = styled.div`
    text-align: left;
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const InfoSpan = styled.span`
    float: right;
`;

const infImg = require('../../../assets/images/info_svg_.svg');

const InfoImage = styled.img`
    height: 16px;
    cursor: pointer;
    opacity: 0.4;
    :hover{
      opacity: 1;
    }
`;

const BSpan = styled.span`
    cursor: pointer;
    opacity: 0.4;
    font-size: 12px;
    font-style: italic;
    :hover{
      opacity: 0.8;
      text-decoration: underline;
    }
`;

const DelSpan = styled.span`
    cursor: pointer;
    color: red;
    :hover{
      opacity: 0.5;
    }
`;
