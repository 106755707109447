import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

// import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import {BlueButton} from "../../ira/ui/Buttons";

import {Input, Textarea} from '../../ira/ui/Inputs'

import Select from 'react-select'

const OPTIONS = [
    {
        label: 'Тег упражнения',
        value: 'exercise'
    },
    {
        label: 'Тег подупражнения',
        value: 'sub-exercise'
    },
]

const MAIN_OPTIONS = [
    {
        label: 'Да',
        value: true
    },
    {
        label: 'Нет',
        value: false
    },
]


export default function UpdateTagForm(props) {
    let {
        onSubmit = () => {
        }, loading = false,
        canEdit = true
    } = props;
    console.log('render: UpdateTagForm props = ', props);
    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    const [type, setType] = useState(props.type == undefined ? 'exercise' : props.type);
    const [isMain, setIsMain] = useState(props.isMain == undefined ? false : props.isMain);

    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                {canEdit == true ?
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value);
                    }}/> :
                    <div>
                        {name}
                    </div>
                }
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                {canEdit == true ?
                    <Textarea value={description} onChange={evt => {
                        setDescription(evt.target.value);
                    }}/> : <div dangerouslySetInnerHTML={{__html: description.replace(/\n/g, '<br/>')}}></div>
                }

            </Field>

            <Field>
                <Label>
                    Тип
                </Label>
                <Select options={OPTIONS} value={OPTIONS.filter(x => (x.value == type))[0]} onChange={x => {
                    setType(x.value);
                }}/>
            </Field>

            {type != 'exercise' ? null :
                <Field>
                    <Label>
                        Является основным
                    </Label>
                    <Select options={MAIN_OPTIONS} value={MAIN_OPTIONS.filter(x => (x.value == isMain))[0]} onChange={x => {
                        setIsMain(x.value);
                    }}/>
                </Field>
            }

            {canEdit == false ? null :
                <Field>
                    <BlueButton onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSubmit({name, description, type, isMain});
                    }}>
                        {loading == true ?
                            <Spin visible={true}/>
                            :
                            <span>
                                Сохранить
                            </span>
                        }

                    </BlueButton>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const Field = styled.div`
  margin-bottom: 10px;
`;

const Label = styled.div`
  font-weight: bold;
`;
