import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {MAIN_ORANGE_COLOR} from "../../ui/Colors";

export default function SendMessageControl(props) {
    const {
        defaultText = '', onSend = txt => {

        },
        sendPlaceholder = 'Ваше сообщение',
        hasAttachment = false,
        sending = false,
        sendOnEnter = true
    } = props;
    const [text, setText] = useState(props.defaultText == undefined ? '' : props.defaultText);

    return (
        <Wrapper>
            <InputPlaceholder>
                <Textarea placeholder={sendPlaceholder}
                          value={text}
                          onKeyPress={event => {
                              if (event.key === 'Enter' && sendOnEnter == true) {
                                  if (text == undefined || text.trim() == '') {
                                      return;
                                  }
                                  onSend(text);
                                  setText('');
                              }
                          }}
                          onChange={event => {
                              if (event.key === 'Enter' && sendOnEnter == true) {
                                  return;
                              }
                              setText(event.target.value);
                          }}/>
            </InputPlaceholder>
            <ButtonPlaceholder>
                <Button style={{width: '100%', margin: 0}} onClick={() => {
                    if (sending == true) {
                        return;
                    }
                    if (text == undefined || text.trim() == '') {
                        return;
                    }
                    onSend(text);
                    setText('');
                }}>
                    <Spin vsible={sending}/>
                    {sending == true ? null :
                        <SendImg src={require('../../../assets/images/plane.svg')}/>
                    }
                </Button>
            </ButtonPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
`;

const InputPlaceholder = styled.div`
    flex: 1;
`;

const ButtonPlaceholder = styled.div`
    width: 80px;
    text-align: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Button = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: orangered;
    color: whitesmoke;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    :hover{
      opacity: 0.5;
    }
`;

const Textarea = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    border: 1px solid whitesmoke;
    outline: none;
    border-radius: 0px;
    height: 100%;
    min-height: 10px;
    :focus{
      border-color: ${MAIN_ORANGE_COLOR};
    }
`;

const SendImg = styled.img`
    height: 40px;
`;
