import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

// import {Button, DangerButton} from "../../ui/Button";
import ChessHelper, {SOLUTIONS_TYPES_OPTIONS} from "../../../helpers/ChessHelper";

import Select from 'react-select'
import HistoryView from "../tools/HistoryView";


import {Button} from '@blueprintjs/core'

export default function SolutionForm(props) {
    let {
        onSave = () => {

        },
        fen,
        canDelete = false,
        history = [],
        onDelete = () => {

        },
        loading = false
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [type, setType] = useState(props.type == undefined ? 'win' : props.type);
    const [description, setDescription] = useState(props.description == undefined ? '' : props.description);
    const [rating, setRating] = useState(props.rating == undefined ? 400 : props.rating);

    // if (fen == undefined) {
    //     return null;
    // }
    // const fenUrl = ChessHelper.getFenImageUrl(fen);

    // console.log('SolutionForm: render: fen = ', fen);

    // console.log('SolutionForm: render: name, description, rating, type = ', name, description, rating, type);

    return (
        <Wrapper>

            {/*<FenImg src={fenUrl}/>*/}

            {/*<div>*/}
            {/*<HistoryView history={history}/>*/}
            {/*</div>*/}

            <Row>
                <Half>
                    <Field>
                        <Label>
                            Рейтинг
                        </Label>
                        <Input value={rating}
                               style={{height: 39}}
                               placeholder={'Рейтинг Элло задачи'} onChange={evt => {
                            setRating(evt.target.value.trim())
                        }}/>
                    </Field>
                </Half>
                <Half>
                    <Field>
                        <Label>
                            Тип
                        </Label>
                        <Select options={SOLUTIONS_TYPES_OPTIONS}
                                isMulti={false}
                                isSearchable={false}
                                isClearable={false}
                                value={(type == undefined) ? undefined : SOLUTIONS_TYPES_OPTIONS.filter(a => (a.value == type))[0]}
                                onChange={a => {
                                    console.log('new type = ', type);
                                    setType(a.value);
                                }}/>
                    </Field>

                </Half>
            </Row>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={name} placeholder={''} onChange={evt => {
                    setName(evt.target.value)
                }}/>
            </Field>


            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={description}
                          style={{height: '4em', minHeight: '2em'}}
                          placeholder={''} onChange={evt => {
                    setDescription(evt.target.value)
                }}/>
            </Field>

            <BottomField>
                <Button loading={loading} minimal={true} text={'Сохранить'} onClick={() => {
                    onSave({
                        name,
                        type,
                        description,
                        rating: +rating
                    });
                }}/>

                {canDelete == false ? null :
                    <DeleteSpan onClick={() => {
                        onDelete();
                    }}>
                        Удалить
                    </DeleteSpan>
                }
            </BottomField>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    box-sizing: border-box;
    width: 100%;
`;

const Left = styled.div`
    width: 240px;
`;

const Right = styled.div`
    flex: 1;
    padding-left: 10px;
`;


const FenImg = styled.img`
    width: 100%;
`;

const Field = styled.div`
    margin-bottom: 2px;
`;

const Label = styled.div`
    font-weight: bold;
    font-size: 10px;
`;

const Row = styled.div`
    
`;

const Half = styled.div`
    box-sizing: border-box;
    width: 50%;
    display: inline-block;
    vertical-align: top;
    :first-of-type{
      padding-right: 5px;
    }
    :last-of-type{
      padding-left: 5px;
    }
`;

const SaveSpan = styled.span`
    cursor: pointer;
    margin-right: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const DeleteSpan = styled.span`
    cursor: pointer;
    color: red;
    opacity: 0.5;
    font-size: 12px;
    :hover{
      opacity: 1;
    }
`;

const BottomField = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
