import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import styled from 'styled-components';

import * as lessonsActions from '../../../redux/actions/LessonsActions'
import SmartSelect from '../../ira/ui/SmartSelect'
import NiceModal from "../../modals/NiceModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UnitView from "../../units/view/UnitView";

export default function IraLessonUnitsSelectorPanel(props) {
    const {
        id, onChange = newIds => {

        }
    } = props;
    const [ratingSort, setRatingSort] = useState('asc');
    const [selectedCourseId, setSelectedCourseId] = useState(undefined);
    const [unitsIds, setUnitsIds] = useState(props.unitsIds == undefined ? [] : props.unitsIds);
    const [selectedUnitViewId, setSelectedUnitViewId] = useState(undefined);

    let {currentUser, loading, lesson, courses, getCourseUnits, getCourse} = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        let allUnits = state.courses.unitsMap.toArray();
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lesson: lesson,
            courses: state.courses.coursesMap.toArray(),
            getCourseUnits: courseId => (courseId == undefined ? allUnits : allUnits.filter(a => (a.courseId == courseId)))
                .sort((a, b) => {
                    let s1 = `${courseId}${a.name}`.toLowerCase();
                    let s2 = `${courseId}${b.name}`.toLowerCase();
                    if (s1 > s2) {
                        return 1;
                    }
                    if (s1 < s2) {
                        return -1;
                    }
                    return 0;
                }),
            getCourse: cId => state.courses.coursesMap.get(cId)
        }
    }, [id]));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(lessonsActions.loadLessonById(id));
    }, [id]);

    console.log('render: courses = ', courses);
    const units = getCourseUnits(selectedCourseId);
    const viewUnit = (selectedUnitViewId == undefined) ? undefined : units.filter(a => (a.id == selectedUnitViewId))[0];

    return (
        <Wrapper>

            <TopFiltersPlaceholder>
                <TopHalf>
                    <SmartSelect
                        placeholder={'Курсы'}
                        options={courses.map(a => ({value: a.id, label: a.name}))}
                        selectedValues={[selectedCourseId]}
                        onSelect={a => {
                            console.log('onSelect: a = ', a);
                            setSelectedCourseId(a[0]);
                        }}
                    />
                </TopHalf>
                <TopHalf>
                    {/*<SmartSelect/>*/}
                </TopHalf>
            </TopFiltersPlaceholder>

            <TableFilterPlaceholder>
                <SideSpan>
                    {`Найдено ${units.length} модулей`}
                </SideSpan>
                <ClHovSpan onClick={() => {
                    setRatingSort({asc: 'desc', desc: 'asc'}[ratingSort]);
                }}>
                    <span style={{marginRight: 10}}>
                        Сортировать по: рейтингу
                    </span>
                    <SortingChevron
                        src={require('../../../assets/images/expand_more.svg')}
                        style={{transform: `rotate(${ratingSort == 'asc' ? 0 : 180}deg)`}}
                    />
                </ClHovSpan>
            </TableFilterPlaceholder>

            <UnitsListPlaceholder>

                <TopTableHeading>
                    <SelCol>

                    </SelCol>
                    <NameCol>
                        Название
                    </NameCol>
                    <FromCol>
                        Откуда
                    </FromCol>
                    <LevelCol>
                        Уровень
                    </LevelCol>
                    <OptionsCol>
                        Опции
                    </OptionsCol>
                </TopTableHeading>

                {units.map((st, k) => {
                    let course = getCourse(st.courseId);
                    let isSelected = (unitsIds.indexOf(st.id) > -1);
                    return (
                        <StudentRow key={`${st.id}_${k}`} selected={isSelected}>
                            <SelCol>
                                <CheckImg
                                    src={isSelected ? require('../../../assets/images/ch_check.svg') : require('../../../assets/images/void_check.svg')}
                                    onClick={() => {
                                        let newIds = unitsIds;
                                        if (isSelected == true) {
                                            newIds = unitsIds.filter(a => (a != st.id));
                                        } else {
                                            newIds = unitsIds.concat([st.id]);
                                        }
                                        setUnitsIds(newIds);
                                        onChange(newIds);
                                    }}
                                />
                            </SelCol>
                            <NameCol>
                                {st.name}
                            </NameCol>
                            <FromCol>
                                {(course == undefined) ? '' : course.name}
                            </FromCol>
                            <LevelCol>
                                {st.level}
                            </LevelCol>
                            <OptionsCol>
                                <TrashImg
                                    src={require('../../../assets/images/eye.svg')}
                                    onClick={() => {
                                        setSelectedUnitViewId(st.id);
                                    }}
                                />
                            </OptionsCol>
                        </StudentRow>
                    )
                })}

            </UnitsListPlaceholder>

            {viewUnit == undefined ? null :
                <NiceModalWithHeading
                    width={940}
                    heading={viewUnit.name}
                    subHeading={(getCourse(viewUnit.courseId) == undefined) ? '' : `Курс "${getCourse(viewUnit.courseId).name}"`}
                    onClose={() => {
                        setSelectedUnitViewId(undefined);
                    }}>

                    <SelectedViewInner>
                        <UnitView items={viewUnit.items} canEdit={false}/>
                    </SelectedViewInner>

                </NiceModalWithHeading>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SelectedViewInner = styled.div`
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
`;

const CheckImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
`;

const TopHalf = styled.div`
    flex: 1;
`;

const LevelCol = styled.div`
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;


const TopFiltersPlaceholder = styled.div`
    box-sizing: border-box;
    background: #085BFF;
    border-radius: 4px;
    margin-bottom: 29px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    padding: 10px;
`;

const TableFilterPlaceholder = styled.div`
    margin-bottom: 13px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SideSpan = styled.div`
    font-weight: normal;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.166667px;
    color: #333539;
    flex-direction: row;
`;

const ClHovSpan = styled(SideSpan)`
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const SortingChevron = styled.img`
    width: 14px;
    height: 14px;
`;

const UnitsListPlaceholder = styled.div`
    background: white;
    border-radius: 4px;
`;

const TrashImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const TopTableHeading = styled.div`
    background: #FFA71B;
    border-radius: 4px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #202224;
    box-sizing: border-box;
    padding-right: 31px;
`;

const StudentRow = styled.div`
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 31px;
    box-sizing: border-box;
    background: ${props => (props.selected != true ? 'white' : 'linear-gradient(0deg, #EDF8FF, #EDF8FF), #FFFFFF;')};
`;

const SelCol = styled.div`
    width: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
`;

const NameCol = styled.div`
    width: 400px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const FromCol = styled.div`
    width: 300px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const OptionsCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

