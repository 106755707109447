import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {DARK_SIDE, MAIN_ORANGE_COLOR} from "../ui/Colors";

import CommonHelper from '../../helpers/CommonHelper'

import Spin from 'arui-feather/spin'
import BottomUserPanel from "./BottomUserPanel";

import Sidebar from 'arui-feather/sidebar'

export default function MyTopLinksBar(props) {
    let {
        currentUser, loading, active, onLogout = () => {
        },
        onCurrentUserClick = () => {

        },
        onUserUpdate = updData => {

        }
    } = props;
    if (currentUser == undefined) {
        return null;
    }
    let {userRole} = currentUser;
    let options = [];
    if (userRole == 'admin') {
        options = adminOptions;
    }
    if (userRole == 'student') {
        options = studentsOptions;
    }
    if (userRole == 'teacher') {
        options = teacherOptions;
    }

    return (
        <Wrapper>

            {options.map((opt, k) => {
                let {label, icon, url, name} = opt;
                let isSelected = (active == name);
                return (
                    <OptionItem selected={isSelected} key={k} onClick={() => {
                        CommonHelper.linkTo(url);
                    }}>
                        {label}
                    </OptionItem>
                )
            })}

        </Wrapper>
    );
}

const topHeight = 30;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    position: relative;
    align-self: stretch;
    justify-content: flex-start;
`;

const OptionItem = styled.div`
    line-height: 32px;
    height: 32px;
    border-bottom: 2px solid ${props => (props.selected == true ? MAIN_ORANGE_COLOR : 'transparent')};
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    color: black;
    opacity: 0.9;
    box-sizing: border-box;
    margin-right: 32px;
    display: flex;
    align-self: center;
    justify-content: center;
    font-size: 14px;
    :hover{
      opacity: 1;
    }
`;

const adminOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    // {
    //     name: 'franchisees',
    //     label: 'Франчайзи',
    //     url: '/franchisees'
    // },
    // {
    //     name: 'schools',
    //     label: 'Школы',
    //     url: '/schools'
    // },
    {
        name: 'students',
        label: 'Ученики',
        url: '/students'
    },
    {
        name: 'teachers',
        label: 'Преподаватели',
        url: '/teachers'
    },
    {
        name: 'courses',
        label: 'Курсы',
        url: '/courses'
    },
    {
        name: 'exercises',
        label: 'Упражнения',
        url: '/exercises'
    },
    {
        name: 'tests',
        label: 'Тесты',
        url: '/tests'
    },
    {
        name: 'exercises_groups',
        label: 'Темы',
        url: '/exercises_groups'
    },
    {
        name: 'tournaments',
        label: 'Мастер-классы',
        url: '/tournaments'
    },
    {
        name: 'settings',
        label: 'Настройки',
        url: '/settings'
    },
    // {
    //     name: 'documents',
    //     label: 'Документы',
    //     url: '/documents'
    // }
];

const teacherOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'students',
        label: 'Ученики',
        url: '/students'
    },
    {
        name: 'groups',
        label: 'Группы',
        url: '/groups'
    },
    {
        name: 'courses',
        label: 'Курсы',
        url: '/courses'
    },
    {
        name: 'exercises',
        label: 'Упражнения',
        url: '/exercises'
    },
    {
        name: 'tournaments',
        label: 'Мастер-классы',
        url: '/tournaments'
    },
    // {
    //     name: 'documents',
    //     label: 'Документы',
    //     url: '/documents'
    // },
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];

const studentsOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },

    // {
    //     name: 'schedule',
    //     label: 'Расписание',
    //     url: '/schedule'
    // },
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];
