import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import UpdateProfilePanel from "../users/panels/UpdateProfilePanel";
import CommonHelper from "../../helpers/CommonHelper";
import {API_ENDPOINT} from "../../constants/config";
import UserProfileBalancePanel from "../users/panels/UserProfileBalancePanel";
import UpdateUserBoardSettingsPanel from "../settings/board/panels/UpdateUserBoardSettingsPanel";

const TABS = [
    {
        label: 'Мой профиль',
        value: 'profile'
    },
    {
        label: 'Баланс',
        value: 'balance',
    },
    // {
    //     label: 'Доска и фигуры',
    //     value: 'board',
    // }
]

export default function UserSettingsApp(props) {
    let {mode} = props.match.params;
    const [tab, setTab] = useState(mode == undefined ? 'profile' : mode);
    const [balance, setBalance] = useState(undefined);

    return (
        <IraTeacherTemplate active={'settings'}>

            <HorHeading>
                <TopLeft>
                    {TABS.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                                CommonHelper.linkTo(`/settings/${a.value}`);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}

                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Wrapper>
                <Heading>
                    {
                        {
                            profile: 'Ваш профиль',
                            balance: 'Ваш баланс',
                            board: 'Настройка доски и фигуры',
                        }[tab]
                    }
                </Heading>

                {tab != 'profile' ? null :
                    <Content>
                        <SubHeading>
                            Данные
                        </SubHeading>
                        <UpdateProfilePanel/>
                    </Content>
                }

                {tab != 'balance' ? null :
                    <>
                        <UserProfileBalancePanel/>
                    </>
                }

                {tab != 'board' ? null :
                    <>
                        <UpdateUserBoardSettingsPanel/>
                    </>
                }



            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding: 40px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 26px;
    color: #333539;
    margin-bottom: 40px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #1F2041;
    margin-bottom: 25px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    background: white;
`;

