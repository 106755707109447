import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Input} from "../../ui/Input";
import {Button} from "../../ui/Button";

import Spin from 'arui-feather/spin'
import InputMask from 'react-input-mask'
import FideInfoTool from "../../fide/tools/FideInfoTool";

import Tabs, {TabItem} from '../../ui/Tabs'
import SelectStyleBoardPanel from "../../settings/board/panels/SelectStyleBoardPanel";
import UpdateUserBoardSettingsPanel from "../../settings/board/panels/UpdateUserBoardSettingsPanel";
import SimpleUploadPanel from "../../upload/SimpleUploadPanel";
import UserSlotsPanel from "../../schedule/panels/UserSlotsPanel";
import RuchessInfoTool from "../../fide/tools/RuchessInfoTool";

export default function UpdateUserInfoForm(props) {
    const [avatar, setAvatar] = useState(props.avatar);
    const [phone, setPhone] = useState(props.phone == undefined ? '' : props.phone);
    const [firstName, setFirstName] = useState(props.firstName == undefined ? '' : props.firstName);
    const [lastName, setLastName] = useState(props.lastName == undefined ? '' : props.lastName);
    const [fideId, setFideId] = useState(props.fideId == undefined ? '' : props.fideId);
    const [ruchessId, setRuchessId] = useState(props.ruchessId == undefined ? '' : props.ruchessId);
    const [uiBoardId, setUiBoardId] = useState(props.uiBoardId == undefined ? '' : props.uiBoardId);

    const {
        onSave = () => {
        },
        loading = false,
        extraContent = undefined
    } = props;

    return (
        <Wrapper>

            <Tabs tabs={[
                {
                    label: 'Профиль',
                    content: (
                        <TabItem>
                            <Field>
                                <Label>
                                    Имя
                                </Label>
                                <Input value={firstName} onChange={evt => setFirstName(evt.target.value)}/>
                            </Field>

                            <Field>
                                <Label>
                                    Фамилия
                                </Label>
                                <Input value={lastName} onChange={evt => setLastName(evt.target.value)}/>
                            </Field>

                            <Field>
                                <Label>
                                    Телефон
                                </Label>
                                <InputMask className={'my_phone'}
                                           mask="+7 (999) 999-99-99"
                                           value={phone} onChange={evt =>
                                    setPhone(evt.target.value)}/>
                            </Field>

                            <Field>
                                <Label>
                                    <a href={'https://fide.com'} target={'_blank'}>
                                        {'Идентификатор игрока на fide.com (FIDE ID) '}
                                    </a> (например, 4162722)
                                </Label>
                                <Input value={fideId} onChange={evt => setFideId(evt.target.value)}/>
                                {fideId == undefined || (fideId.trim() == '' || window.isNaN(fideId.trim())) ? null :
                                    <div style={{marginTop: 10}}>
                                        <FideInfoTool playerId={fideId}/>
                                    </div>
                                }
                            </Field>

                            <Field>
                                <Label>
                                    <a href={'https://ratings.ruchess.ru'} target={'_blank'}>
                                        {'Идентификатор игрока на ruchess.ru (RUCHESS ID) '}
                                    </a> (например, 527)
                                </Label>
                                <Input value={ruchessId} onChange={evt => setRuchessId(evt.target.value)}/>
                                {ruchessId == undefined || (ruchessId.trim() == '' || window.isNaN(ruchessId.trim())) ? null :
                                    <div style={{marginTop: 10}}>
                                        <RuchessInfoTool playerId={ruchessId}/>
                                    </div>
                                }
                            </Field>

                            <Field>
                                <Button onClick={() => {
                                    onSave({phone, firstName, lastName, fideId, ruchessId, uiBoardId, avatar});
                                }}>
                                    <Spin visible={loading}/>
                                    {loading == true ? null :
                                        <span>
                                         Сохранить
                                        </span>
                                    }
                                </Button>

                                {extraContent}

                            </Field>

                        </TabItem>
                    )
                },
                {
                    label: 'Аватар',
                    content: (
                        <TabItem>
                            <SimpleUploadPanel onUploaded={url => {
                                setAvatar(url);
                            }}>
                                {avatar == undefined ?
                                    <span>
                                        загрузите аватар
                                    </span>
                                    :
                                    <AvaImg src={avatar}/>
                                }
                            </SimpleUploadPanel>

                            <Field>
                                <Button onClick={() => {
                                    onSave({phone, firstName, lastName, fideId, uiBoardId, avatar});
                                }}>
                                    <Spin visible={loading}/>
                                    {loading == true ? null :
                                        <span>
                                         Сохранить
                                        </span>
                                    }
                                </Button>

                                {extraContent}

                            </Field>

                        </TabItem>
                    )
                },
                {
                    label: 'Дизайн доски',
                    content: (
                        <TabItem>
                            <UpdateUserBoardSettingsPanel userId={props.id}/>
                        </TabItem>
                    )
                },
                {
                    label: 'Свободное расписание',
                    content: (
                        <TabItem>
                            <UserSlotsPanel userId={props.id}/>
                        </TabItem>
                    )
                },

            ]}/>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;

const AvaImg = styled.img`
    height: 120px;
`;
