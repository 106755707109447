import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import WeekCalendar from "../../ira/calendars/WeekCalendar";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";

import {Label, Input} from "../../ira/ui/Inputs";
import SmartSelect from "../../ira/ui/SmartSelect";

import {BlueButton} from "../../ira/ui/Buttons";
import * as lessonsActions from '../../../redux/actions/LessonsActions';
import * as teacherGroupsActions from '../../../redux/actions/TeacherGroupsActions';
import SimpleGroupView from "../../ira/ui/group/SimpleGroupView";
import NiceConfirm from "../../modals/NiceConfirm";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import IraEditLessonContentPanel from "./IraEditLessonContentPanel";
import CommonHelper from "../../../helpers/CommonHelper";

const getFree30MinSlots = (dayTimestamp, lessons) => {
    let step = 30 * 60 * 1000;
    let from = +moment(dayTimestamp).startOf('day');
    let to = +moment(dayTimestamp).endOf('day');
    let dLessons = lessons.filter(a => ((+a.startTimestamp >= from && +a.startTimestamp < to) || (+a.endTimestamp >= from && +a.endTimestamp < to)))

    let arr = Array.from({length: 60}).map((a, i) => (+from + step * i));
    let result = [];
    for (let i in arr) {
        let a = arr[i];
        let f = true;
        for (let j in dLessons) {
            let l = dLessons[j];
            if (+a >= +l.startTimestamp && +a < +l.endTimestamp) {
                f = false;
            }
        }
        if (f) {
            result.push(a);
        }
    }
    return result;
}

const getNearestDaysFromToday = (n = 14) => {
    let from = +moment().startOf('day');
    let step = 86400000;
    return Array.from({length: n}).map((a, i) => (+from + i * step));
}

const isInside = (from, to, t) => {
    return (+t >= +from && +t < to);
}

function getRelativeCoordinates(event) {
    let windTop = window.pageYOffset || document.documentElement.scrollTop;
    let rrr = document.getElementById('template_right_content');
    console.log('windTop = ', windTop);
    let referenceElement = document.getElementById('content_calendar_placeholder');
    const position = {
        x: event.pageX,
        y: event.pageY
    };

    const offset = {
        left: referenceElement.offsetLeft,
        top: referenceElement.offsetTop
    };

    let reference = referenceElement.offsetParent;

    while (reference) {
        offset.left += reference.offsetLeft;
        offset.top += reference.offsetTop;
        reference = reference.offsetParent;
    }

    return {
        x: position.x - offset.left,
        y: position.y - offset.top + rrr.scrollTop
    };

}

const NEAREST_DAYS = getNearestDaysFromToday(45);

export default function IraTeacherLessonsPanel(props) {
    const {
        teacherId, onSelect = (lId, l) => {

        }
    } = props;
    const dispatch = useDispatch();
    let {currentUser, loading, lessons, groupsMap, uId, teacherGroups, getGroupById, getLessonById} = useMappedState(useCallback(state => {
        let uId = (teacherId == undefined) ? state.users.currentUserId : teacherId;
        let teacher = state.users.usersMap.get(uId);
        let teacherGroups = state.teacherGroups.teacherGroupsMap.toArray().filter(g => (g.teacherId == uId)).filter(a => (a.deleted != true));
        let grSet = teacherGroups.reduce((sst, gr) => sst.add(gr.id), Set());
        let grMap = teacherGroups.reduce((sst, gr) => sst.set(gr.id, gr), Map());
        let lessons = state.lessons.lessonsMap.toArray().filter(u => grSet.has(u.teacherGroupId)).sort((a, b) => (+b.startTimestamp - +a.startTimestamp));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lessons: lessons,
            uId: uId,
            currentUserIsTeacher: (teacher != undefined && 'teacher' == teacher.userRole),
            groupsMap: grMap,
            teacherGroups: teacherGroups,
            getGroupById: grId => state.teacherGroups.teacherGroupsMap.get(grId),
            getLessonById: lId => (lId == undefined ? undefined : state.lessons.lessonsMap.get(lId))
        }
    }, [teacherId]));

    useEffect(() => {
        dispatch(lessonsActions.loadAllLessons()); // todo: optimize
        dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(uId))
    }, []);


    const [weekTimestamp, setWeekTimestamp] = useState();
    let startOfWeek = +moment(weekTimestamp).startOf('isoweek').startOf('day');
    let endOfWeek = +moment(weekTimestamp).endOf('isoweek').endOf('day');
    let weekLessons = lessons.filter(a => (+a.startTimestamp > +startOfWeek && +a.startTimestamp <= endOfWeek));
    console.log('weekLessons = ', weekLessons);

    const [addTimestamp, setAddTimestamp] = useState(undefined);
    const [addDayTimestamp, setAddDayTimestamp] = useState(undefined);

    const [addEndTimestamp, setAddEndTimestamp] = useState(undefined);
    const [selectedGroupId, setSelectedGroupId] = useState(undefined);
    const [deleteId, setDeleteId] = useState(undefined);

    const [editTasksLessonId, setEditTasksLessonId] = useState(undefined);

    const [selectedLessonId, setSelectedLessonId] = useState(undefined);
    const [selectedLessonModalCoords, setSelectedLessonModalCoords] = useState({x: 0, y: 0});
    const [mode, setMode] = useState('create');

    useEffect(() => {
        setAddEndTimestamp(+addTimestamp + 3600000);
    }, [addTimestamp]);

    let now = +new Date();
    let endOfSelectedDay = +moment(addDayTimestamp).endOf('day');
    let free30MinFromSlots = (addDayTimestamp == undefined) ? [] : getFree30MinSlots(addDayTimestamp, (mode == 'update' ? [] : weekLessons));
    free30MinFromSlots = free30MinFromSlots.filter(a => (+a > +now));

    let freeEnd30MinFromSlots = (addTimestamp == undefined) ? [] : free30MinFromSlots.filter(a => (+a > +addTimestamp));

    free30MinFromSlots = free30MinFromSlots.filter(a => (+a < +endOfSelectedDay));
    let realFreeEnd30MinFromSlots = [];
    for (let i in freeEnd30MinFromSlots) {
        let curr = freeEnd30MinFromSlots[i];
        let prev = (i == 0) ? undefined : freeEnd30MinFromSlots[i - 1];
        if (prev == undefined) {
            realFreeEnd30MinFromSlots.push(curr);
            continue;
        }
        let diff = curr - prev;
        if (diff == 30 * 60 * 1000) {
            realFreeEnd30MinFromSlots.push(curr);
        } else {
            break;
        }
    }
    realFreeEnd30MinFromSlots = realFreeEnd30MinFromSlots.slice(0, 6);
    console.log('render: selectedLessonId, selectedLessonModalCoords = ', selectedLessonId, selectedLessonModalCoords);
    let selectedLesson = getLessonById(selectedLessonId);
    let updateLessonDetailsModalVisible = (addTimestamp != undefined || (selectedLesson != undefined && mode == 'update'));

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopLeftPlaceholder>

                    <TopHeading>
                        Ваше расписание
                    </TopHeading>

                    <ArrowsPlaceholder>
                        <ArrowItem onClick={() => {
                            setWeekTimestamp(+moment(weekTimestamp).add(-1, 'weeks'));
                            setSelectedLessonId(undefined);
                        }}>
                            <ArrowImg
                                src={require('../../../assets/images/arrow_r.svg')}
                                style={{transform: `rotate(180deg)`}}
                            />
                        </ArrowItem>
                        <ArrowItem onClick={() => {
                            setWeekTimestamp(+moment(weekTimestamp).add(1, 'weeks'));
                            setSelectedLessonId(undefined);
                        }}>
                            <ArrowImg
                                src={require('../../../assets/images/arrow_r.svg')}
                            />
                        </ArrowItem>
                    </ArrowsPlaceholder>

                    <CurrentDaySpan>
                        {`${moment(weekTimestamp).format('MMMM')} ${moment(weekTimestamp).startOf('isoweek').format('D')} - ${moment(weekTimestamp).endOf('isoweek').format('D')}, ${moment(weekTimestamp).format('YYYY')}`}
                    </CurrentDaySpan>

                </TopLeftPlaceholder>
                <TopRightPlaceholder>

                </TopRightPlaceholder>
            </TopPlaceholder>

            <ContentPlaceholder id={`content_calendar_placeholder`}>
                <WeekCalendar weekTimestamp={weekTimestamp} renderHour={t => {
                    let startOfHour = +moment(t).startOf('hour');
                    let endOfHour = +moment(t).endOf('hour');
                    let hourLessons = weekLessons.filter(a => (+a.startTimestamp >= +startOfHour && +a.startTimestamp < endOfHour)); // if started this hour
                    let lastingLessons = weekLessons.filter(a => (isInside(a.startTimestamp, a.endTimestamp, t)));

                    let isFuture = (+t > +now - 3600000);
                    let lesson = hourLessons[0];
                    let lastingLesson = lastingLessons[0];


                    let offsetFromHour = (lesson == undefined) ? 0 : (Math.max(+lesson.startTimestamp, t) - +t);
                    if (lesson == undefined && lastingLesson != undefined) {
                        offsetFromHour = 0;
                    }

                    let duration = (lesson == undefined) ? 0 : Math.min(+lesson.endTimestamp, endOfHour + 1) - +lesson.startTimestamp;
                    if (lastingLesson != undefined && duration == 0) {
                        duration = Math.min(+lastingLesson.endTimestamp, endOfHour + 1) - Math.max(+lastingLesson.startTimestamp, t);
                    }

                    let teacherGroup = (lesson == undefined) ? undefined : getGroupById(lesson.teacherGroupId);
                    let durPerc = 100.0 * duration / 3600000.0;
                    let offsetFromHourPer = 100.0 * offsetFromHour / 3600000.0;
                    // let durPerc = 100.0 - offsetFromHour;
                    let anyLesson = lesson || lastingLesson;
                    let isSelected = (anyLesson != undefined && anyLesson.id == selectedLessonId);
                    return (
                        <InnerHour key={t} onClick={evt => {
                            if (anyLesson == undefined) {
                                setSelectedLessonId(undefined);
                                return;
                            }
                            console.log('click! evt = ', evt);
                            setSelectedLessonModalCoords(getRelativeCoordinates(evt));
                            if (anyLesson == undefined) {
                                return;
                            }
                            setSelectedLessonId(anyLesson.id);
                        }}>

                            {lesson != undefined || lastingLesson != undefined || !isFuture ? null :
                                <VoidHour onClick={() => {
                                    setAddDayTimestamp(+moment(t).startOf('day'));
                                    setAddTimestamp(t);
                                }}>
                                    <PlusSquare src={require('../../../assets/images/mdi_add_box.svg')}/>
                                </VoidHour>
                            }

                            {(lesson == undefined && lastingLesson == undefined) ? null :
                                <GroupHourInner onClick={(evt) => {

                                }}>
                                    <GroupHour
                                        selected={isSelected}
                                        style={{height: `${durPerc}%`, top: `${offsetFromHourPer}%`}}>
                                        {teacherGroup == undefined ? '' : <GrHourName>{teacherGroup.name}</GrHourName>}
                                    </GroupHour>
                                </GroupHourInner>
                            }

                        </InnerHour>
                    )
                }}/>

                {selectedLesson == undefined ? null :
                    <SelectedLessonBox {...selectedLessonModalCoords} >
                        <SimpleGroupView group={getGroupById(selectedLesson.teacherGroupId)}/>
                        <SelectedLessonRow style={{marginTop: 18}}>
                            <SelectedLessonRowLabel>
                                Дата:
                            </SelectedLessonRowLabel>
                            <SelectedLessonRowValue>
                                {moment(selectedLesson.startTimestamp).format('D MMMM')}
                            </SelectedLessonRowValue>
                        </SelectedLessonRow>
                        <SelectedLessonRow>
                            <SelectedLessonRowLabel>
                                Время:
                            </SelectedLessonRowLabel>
                            <SelectedLessonRowValue>
                                {`${moment(selectedLesson.startTimestamp).format('HH:mm')} - ${moment(selectedLesson.endTimestamp).format('HH:mm')}`}
                            </SelectedLessonRowValue>
                        </SelectedLessonRow>
                        <SelectedLessonBottomRow>
                            <BlueButton style={{marginRight: 19}} onClick={() => {
                                CommonHelper.linkTo(`/lesson/${selectedLesson.id}`, true);
                                // setEditTasksLessonId(selectedLesson.id);
                            }}>
                                МАТЕРИАЛЫ
                            </BlueButton>
                            <SelControlIcon
                                src={require('../../../assets/images/edit_lesson_icon.svg')}
                                style={{marginRight: 19}}
                                onClick={() => {
                                    setSelectedGroupId(selectedLesson.teacherGroupId);
                                    setAddDayTimestamp(+moment(selectedLesson.startTimestamp).startOf('day'));
                                    setAddTimestamp(selectedLesson.startTimestamp);
                                    setAddEndTimestamp(selectedLesson.endTimestamp);
                                    setMode('update');
                                }}
                            />
                            <SelControlIcon
                                src={require('../../../assets/images/remove_lesson_icon.svg')}
                                onClick={() => {
                                    setDeleteId(selectedLesson.id);
                                }}
                            />
                        </SelectedLessonBottomRow>
                    </SelectedLessonBox>
                }


            </ContentPlaceholder>

            {!updateLessonDetailsModalVisible ? null :
                <NiceModalWithHeading
                    heading={`${mode == 'update' ? 'Обновить' : 'Создать'} занятие`}
                    subHeading={''}
                    onClose={() => {
                        setSelectedGroupId(undefined);
                        setAddTimestamp(undefined);
                        setAddDayTimestamp(undefined);
                        setMode('create');
                        setSelectedLessonId(undefined);
                    }}
                >

                    <Field>
                        <Label>
                            Выберите группу
                        </Label>
                        <SmartSelect
                            placeholder={'Группа'}
                            options={teacherGroups.map(a => ({value: a.id, label: a.name}))}
                            isMulti={false}
                            closeOnSelect={true}
                            selectedValues={selectedGroupId == undefined ? (selectedLesson != undefined ? [selectedLesson.teacherGroupId] : []) : [selectedGroupId]}
                            onSelect={a => {
                                setSelectedGroupId(a[0]);
                            }}
                        />
                    </Field>

                    <Row>
                        <Field style={{width: 120, marginRight: 30}}>
                            <Label>
                                Дата
                            </Label>
                            <SmartSelect
                                placeholder={'Дата'}
                                options={NEAREST_DAYS.map(a => ({value: a, label: `${moment(a).format('D MMMM')}`}))}
                                isMulti={false}
                                closeOnSelect={true}
                                selectedValues={addDayTimestamp == undefined ? (selectedLesson != undefined ? [+moment(selectedLesson.startTimestamp).startOf('day')] : []) : [addDayTimestamp]}
                                onSelect={a => {
                                    setAddDayTimestamp(a[0]);
                                }}
                            />
                        </Field>
                        <Field style={{width: 120}}>
                            <Label>
                                Время
                            </Label>
                            <SmartSelect
                                placeholder={'Начало'}
                                options={free30MinFromSlots.map(a => ({
                                    value: a,
                                    label: `${moment(a).format('HH:mm')}`
                                }))}
                                isMulti={false}
                                closeOnSelect={true}
                                selectedValues={addTimestamp == undefined ? (selectedLesson != undefined ? [selectedLesson.startTimestamp] : []) : [addTimestamp]}
                                onSelect={a => {
                                    setAddTimestamp(a[0]);
                                }}
                            />
                        </Field>
                        <Field style={{width: 40}}>
                            <Label style={{opacity: 0}}>
                                __
                            </Label>
                            <Plug>
                                до
                            </Plug>
                        </Field>
                        <Field style={{width: 120, marginRight: 30}}>
                            <Label style={{opacity: 0}}>
                                по
                            </Label>
                            <SmartSelect
                                placeholder={'Окончание'}
                                options={realFreeEnd30MinFromSlots.map(a => ({
                                    value: a,
                                    label: `${moment(a).format('HH:mm')}`
                                }))}
                                isMulti={false}
                                closeOnSelect={true}
                                selectedValues={addEndTimestamp == undefined ? (selectedLesson != undefined ? [selectedLesson.endTimestamp] : []) : [addEndTimestamp]}
                                onSelect={a => {
                                    setAddEndTimestamp(a[0]);
                                }}
                            />
                        </Field>
                    </Row>

                    <Field>
                        <BlueButton disabled={loading || (selectedGroupId == undefined)} onClick={async () => {
                            if (selectedGroupId == undefined || loading == true) {
                                return;
                            }
                            let d = {
                                teacherGroupId: selectedGroupId,
                                startTimestamp: addTimestamp,
                                endTimestamp: (addEndTimestamp == undefined) ? (+addTimestamp + 3600000) : addEndTimestamp
                            };
                            if (mode == 'create') {
                                await dispatch(lessonsActions.createLesson(d));
                            } else {
                                if (selectedLessonId != undefined) {
                                    await dispatch(lessonsActions.updateLesson({...d, id: selectedLessonId}));
                                    setSelectedLessonId(undefined);
                                }
                            }
                            setAddTimestamp(undefined);
                            setMode('create');
                        }}>
                            <Spin theme={'alfa-on-color'} visible={loading}/>
                            {loading == true ? null : (mode == 'update' ? 'ОБНОВИТЬ' : 'СОЗДАТЬ')}
                        </BlueButton>
                    </Field>

                </NiceModalWithHeading>
            }


            {deleteId == undefined ? null :
                <NiceConfirm
                    loading={loading}
                    heading={'Удалить урок!'}
                    subHeading={'Вы уверены, что хотите удалить этот урок?'}
                    onConfirm={async () => {
                        await dispatch(lessonsActions.deleteLesson(deleteId));
                        setSelectedLessonId(undefined);
                        setDeleteId(undefined);
                    }}
                    onCancel={() => {
                        setDeleteId(undefined);
                    }}
                />
            }

            {editTasksLessonId == undefined ? null :
                <BrutalOverlayModal onClose={() => {
                    setEditTasksLessonId(undefined);
                }}>

                    <InnerFPlaceholder>

                        <IraEditLessonContentPanel
                            id={editTasksLessonId}
                        />

                    </InnerFPlaceholder>

                </BrutalOverlayModal>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const InnerFPlaceholder = styled.div`
    width: 1020px;
    margin: 0 auto;
    @media(max-width: 1020px){
      
    }
`;

const SelControlIcon = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
    
`;

const SelectedLessonBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 22px;
`;

const SelectedLessonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
`;

const SelectedLessonRowLabel = styled.div`
    color: rgba(31, 32, 65, 0.5);
    margin-right: 5px;
`;

const SelectedLessonRowValue = styled.div`
    color: rgba(31, 32, 65);
`;

const SelectedLessonBox = styled.div`
    position: absolute;
    top: ${props => props.y}px;
    left: ${props => props.x}px;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px 20px 40px rgba(8, 91, 255, 0.2);
    border-radius: 4px;
    padding: 30px;
`;

const Plug = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 44px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 28px;
`;

const Field = styled.div`
    margin-bottom: 28px;
    :last-of-type{
      margin-bottom: 0px;
    }
`;

const GrHourName = styled.div`
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
`;


const GroupHour = styled.div`
    background: ${props => (props.selected ? '#085BFF' : 'rgba(163,110,255,0.5)')};
    //background: #FFC02C;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: ${props => (props.selected ? '#FFFFFF' : '#202224')};
    mix-blend-mode: normal;
    opacity: 0.9;
    box-sizing: border-box;
    width: 100%;
    font-weight: bold;
    padding: 5px;
    overflow: hidden;
    position: absolute;
    z-index: 1;
    left: 0px;
    right: 0px;
    cursor: pointer;
`;

const GroupHourInner = styled.div`
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
`;

const InnerHour = styled.div`
    width: 100%;
    height: 100%;
`;

const PlusSquare = styled.img`
    width: 24px;
    height: 24px;
    display: none;
`;

const VoidHour = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    :hover{
      background: rgba(134,209,255,0.10);
    }
    &:hover ${PlusSquare}{
      display: block;
    }
`;

const TopHeading = styled.div`
    font-weight: normal;
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.166667px;
    color: #333539;
    margin-right: 20px;
`;

const CurrentDaySpan = styled.div`
    margin-left: 20px;
    font-weight: normal;
    font-size: 20px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #202224;
    opacity: 0.8;
`;

const ArrowsPlaceholder = styled.div`
    height: 44px;
    background: #FFFFFF;
    border: 1px solid rgba(31, 32, 65, 0.25);
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    user-select: none !important;
`;

const ArrowItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 42px;
    cursor: pointer;
    :first-of-type{
      border-right: 0.4px solid rgba(135,152,173,0.4);;
    }
    :hover{
      opacity: 0.5;
    }
`;

const ArrowImg = styled.img`
    height: 12.5px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
`;

const TopLeftPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ContentPlaceholder = styled.div`
    position: relative;
`;
