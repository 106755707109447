import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import axios from 'axios'

import {Map, Set} from 'immutable'

import * as actions from '../../../redux/actions/LessonsActions'
import * as smartExercisesActions from '../../../redux/actions/SmartExercisesActions'
import * as coursesActions from '../../../redux/actions/CoursesActions'
import CommonHelper from "../../../helpers/CommonHelper";
import IllegalWorkspace from "../../rubius/exercise/Workspace/IllegalWorkspace";
import Workspace from "../../rubius/exercise/Workspace/Workspace";
import UnitView from "../../units/view/UnitView";
import SmartExercisesAPI from "../../../api/SmartExercisesAPI";
import {API_ENDPOINT} from "../../../constants/config";
import ls from "local-storage";

import selectThemeImg from '../../../assets/images/select_theme.png'
import CoursesAPI from "../../../api/CoursesAPI";

const TABS = [
    {
        label: 'Теория',
        value: 'theory'
    },
    {
        label: 'Упражнения',
        value: 'exercises'
    }
];

const getSortedItems = arr => {
    console.log('getSortedItems occured! arr = ', arr);
    if (arr == undefined) {
        return [];
    }
    return arr.sort((a, b) => {
        let s1 = `${a.name}`.toLowerCase();
        let s2 = `${b.name}`.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });
}

export default function StudentCoursePanel(props) {
    let {id} = props;
    const [tab, setTab] = useState('exercises');
    const [selectedId, setSelectedId] = useState(undefined);

    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);

    const [subCopeMap, setSubCopeMap] = useState({});
    const [copeT, setCopeT] = useState(0);

    const {
        lesson,
        loading,
        currentUserId,
        currentUser,
        getExercise,
        getUnit,
        course,
        units
    } = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        let course = state.courses.coursesMap.get(id);
        let sc = (lesson == undefined) ? undefined : state.schools.schoolsMap.get(lesson.schoolId);

        return {
            course: course,
            lesson: state.lessons.lessonsMap.get(id),
            school: sc,
            loading: state.lessons.loading || state.smartExercises.loading || state.courses.loading,
            currentUserId: state.users.currentUserId,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            getExercise: exId => state.smartExercises.smartExercisesMap.get(exId),
            getUnit: exId => state.courses.unitsMap.get(exId),
            units: state.courses.unitsMap.toArray().filter(x => (x.courseId == id))
        }
    }, [id]));
    const dispatch = useDispatch();
    const [allStudentUnitsSet, setAllStudentUnitsSet] = useState(Set());

    useEffect(() => {
        CoursesAPI.getStudentUnits(currentUserId).then(arr => {
            setAllStudentUnitsSet(arr.reduce((st, x) => st.add(x.id), Set()));
        })
    }, [currentUserId]);

    useEffect(() => {
        dispatch(coursesActions.loadCourseById(id)).then(pld => {
            console.log('--->>> course loaded: pld.course = ', pld.course);

        });
        // dispatch(actions.loadLessonById(id)).then(pld => {
        //     if (pld != undefined && pld.lessons != undefined) {
        //         let l = pld.lessons[0];
        //         if (l != undefined) {
        //             let tx = (l.exercisesIds != undefined && l.exercisesIds.length > 0) ? 'exercises' : (l.unitsIds != undefined && l.unitsIds.length > 0 ? 'units' : undefined);
        //             setTab(tx);
        //         }
        //     }
        // }).catch(exc => {
        //
        // });

        dispatch(coursesActions.loadCourseUnits(id));
        dispatch(smartExercisesActions.loadAllSmartExercises()); // todo: optimize
    }, [id]);


    // let {exercisesIds = [], unitsIds = [], testsIds = []} = (lesson == undefined ? {} : course);

    // useEffect(() => {
    //     let exercises = exercisesIds.map(exId => getExercise(exId)).filter(a => (a != undefined));
    //     let units = unitsIds.map(exId => getUnit(exId)).filter(a => (a != undefined));
    //     let leftItems = getSortedItems((tab == 'exercises') ? exercises : units);
    //     // let leftItems = (tab == 'exercises') ? exercisesIds : unitsIds;
    //     if (leftItems.length > 0) {
    //         setSelectedId(leftItems[0].id);
    //     }
    // }, [tab, (lesson == undefined ? '' : lesson.id), exercisesIds.length, unitsIds.length, loading]);

    useEffect(() => {
        SmartExercisesAPI.getUserExerciseResultsMap(selectedExerciseId).then(mp => {
            console.log('--->>> subCopeMap = ', subCopeMap);
            setSubCopeMap(mp);
        });
    }, [copeT, selectedExerciseId]);

    if (loading == true) {
        return (
            <Code/>
        )
    }
    if (course == undefined) {
        return null;
    }

    // let {exercisesIds = [], unitsIds = [], testsIds = []} = lesson;
    let tabs = [];

    // if (units.length > 0) {
    //     tabs = tabs.concat([
    //         {
    //             label: 'Теория',
    //             value: 'units'
    //         }
    //     ]);
    // }
    // if (exercisesIds.length > 0) {
    //     tabs = tabs.concat([
    //         {
    //             label: 'Упражнения',
    //             value: 'exercises'
    //         }
    //     ]);
    // }


    // let units = unitsIds.map(exId => getUnit(exId)).filter(a => (a != undefined));
    let leftItems = (tab == 'exercises') ? exercises : units;
    let sortedUnits = getSortedItems(units);
    sortedUnits = sortedUnits.filter(x => allStudentUnitsSet.has(x.id));

    leftItems = getSortedItems(leftItems);

    let selectedUnit = (selectedId == undefined) ? undefined : units.filter(a => (a.id == selectedId))[0];
    let exercisesIds = (selectedUnit == undefined || selectedUnit.exercisesIds == undefined) ? [] : selectedUnit.exercisesIds;
    let exercises = exercisesIds.map(exId => getExercise(exId)).filter(a => (a != undefined));

    console.log('--->>> exercises = ', exercises);

    let sortedExercises = getSortedItems(exercises);

    let selectedExercise = (setSelectedExerciseId == undefined) ? undefined : exercises.filter(a => (a.id == selectedExerciseId))[0];

    console.log('selectedExercise = ', selectedExercise);

    return (
        <Wrapper>

            <Left>
                <LogoPlaceholder>
                    <Logo src={require('../../templates/images/top_logo_frame.svg')}/>
                </LogoPlaceholder>

                <TopLeftBackPlaceholder>
                    <TopLeftBackSpan onClick={() => {
                        if (selectedUnit != undefined) {
                            setSelectedId(undefined);
                        } else {
                            CommonHelper.linkTo('/courses');
                        }
                    }}>
                        {(selectedUnit == undefined) ? '<Вернуться к курсам' : '<Вернуться назад'}
                    </TopLeftBackSpan>
                </TopLeftBackPlaceholder>

                <LessonNamePlaceholder>
                    {course.name}
                    {selectedUnit == undefined ? null :
                        <span>
                            <span style={{marginLeft: 5, marginRight: 5}}>
                                /
                            </span>
                            {selectedUnit.name}
                        </span>
                    }
                </LessonNamePlaceholder>

                <LeftTabsPlaceholder>
                    {selectedUnit == undefined ?
                        sortedUnits.map((a, i) => {
                            return (
                                <LeftTabItem
                                    selected={(a.id == selectedId)}
                                    key={a.id}
                                    onClick={() => {
                                        setSelectedId(a.id);
                                        setTab('theory');
                                    }}>
                                    {`${a.name}`}
                                </LeftTabItem>
                            )
                        }) :
                        <div>
                            <div>
                                <LeftTabItem selected={(tab == 'theory')} onClick={() => {
                                    setSelectedExerciseId(undefined);
                                    setTab('theory');
                                }}>
                                    Теория
                                </LeftTabItem>
                            </div>
                            {sortedExercises.map((a, i) => {
                                return (
                                    <LeftTabItem
                                        selected={(a.id == selectedExerciseId)}
                                        key={a.id}
                                        onClick={() => {
                                            setSelectedExerciseId(a.id);
                                            setTab('exercises');
                                        }}>
                                        {`${+i + 1}. ${a.name}`}
                                    </LeftTabItem>
                                )
                            })}
                        </div>
                    }
                </LeftTabsPlaceholder>

            </Left>

            <Right>
                {/*{selectedUnit == undefined ? null :*/}
                {/*    <TopRightNavPlaceholder>*/}
                {/*        {TABS.map((a, i) => {*/}
                {/*            return (*/}
                {/*                <TopTab key={`${a.value}_${i}`}*/}
                {/*                        selected={(a.value == tab)}*/}
                {/*                        onClick={() => {*/}
                {/*                            setTab(a.value);*/}
                {/*                        }}>*/}
                {/*                    {a.label}*/}
                {/*                </TopTab>*/}
                {/*            )*/}
                {/*        })}*/}
                {/*    </TopRightNavPlaceholder>*/}
                {/*}*/}

                <RightContent key={`${selectedId}_${tab}_${selectedExerciseId}`}
                              style={{
                                  background: (selectedUnit == undefined ? 'transparent' : 'white'),
                                  border: (selectedUnit == undefined ? 'none' : undefined)

                              }}
                >

                    {selectedUnit != undefined ? null :
                        <div>
                            <div style={{textAlign: 'center', fontSize: 24, fontWeight: 'bold', marginBottom: 50}}>
                                Выберите урок
                            </div>
                            <div>
                                <SelectImg src={selectThemeImg}/>
                            </div>
                        </div>
                    }

                    {(selectedExercise == undefined || tab != 'exercises') ? null :

                        <React.Fragment>

                            {selectedExercise.isIllegal ?
                                <IllegalWorkspace {...selectedExercise}
                                                  boardWidthFraction={0.6}
                                                  onExerciseFinish={() => {

                                                  }}
                                                  onStepLimitReached={() => {

                                                  }}
                                                  onSubExerciseStart={() => {

                                                  }}
                                                  onTimeFinished={() => {

                                                  }}/>
                                :
                                <Workspace {...selectedExercise}
                                           allowHandleKeyEvents={() => {

                                           }}
                                           boardWidthFraction={0.6}
                                           onSubExerciseStart={(a, b, c) => {
                                               console.log('onSubExerciseStart: a, b, c = ', a, b, c);
                                           }}
                                           onSubExerciseEnd={async (a, b, c) => {
                                               let tsk = JSON.parse(JSON.stringify(a.task));
                                               let dd = {
                                                   [tsk.id]: {
                                                       result: tsk.result,
                                                       rating: tsk.rating,
                                                       duration: tsk.duration,
                                                       assessment: tsk.assessment
                                                   }
                                               };
                                               await SmartExercisesAPI.saveUserExerciseAnswer(selectedExercise.id, dd);
                                               setCopeT(+new Date());
                                           }}
                                           onExerciseFinish={(a, b, c) => {
                                               console.log('onExerciseFinish: a, b, c = ', a, b, c);
                                           }}
                                           onTimeFinished={(a, b, c) => {
                                               // console.log('onTimeFinished: a, b, c = ', a, b, c);
                                           }}
                                           onTimerTick={(a, b, c) => {
                                               // console.log('onTimerTick: a, b, c = ', a, b, c);
                                           }}/>}

                        </React.Fragment>

                    }

                    {(selectedUnit == undefined || tab != 'theory') ? null :
                        <React.Fragment>
                            <UnitView items={selectedUnit.items} canEdit={false}/>
                        </React.Fragment>
                    }

                </RightContent>

            </Right>

        </Wrapper>
    );
}

const topHeight = 70;
const leftWidth = 300;

const SelectImg = styled.img`
  width: min(100vw - 520px, 620px);
  margin: 0 auto;
  display: block;
`;

const LeftTabsPlaceholder = styled.div`

`;

const SelectedExerciseSolutionsPlaceholder = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LeftTabItem = styled.div`
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.617143px;
  color: #333333;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  background: ${props => (props.selected == true ? 'rgba(134, 209, 255, 0.2)' : 'white')};
  cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #D5D5D5;
`;

const LessonNamePlaceholder = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 32px;
  color: #333333;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
`;

const RightContent = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(8, 91, 255, 0.1);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 30px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  margin-top: 10px;
`;

const TopLeftBackPlaceholder = styled.div`
  padding-left: 20px;
`;

const TopLeftBackSpan = styled.span`
  cursor: pointer;
`;

const TopRightNavPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

const TopTab = styled.div`
  margin-left: 20px;
  margin-right: 20px;
  font-size: 19px;
  line-height: 23px;
  font-weight: ${props => (props.selected == true ? 'bold' : 'normal')};
  color: ${props => (props.selected == true ? 'black' : 'color: rgba(31, 32, 65, 0.5)')};
  cursor: pointer;
`;

const LogoPlaceholder = styled.div`
  width: 100%;
  height: ${topHeight}px;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 100%;
  height: 100%;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #F4F4F6;
  position: fixed;
  z-index: 1;
  top: 0px;
  left: 0px;
`;

const Left = styled.div`
  width: ${leftWidth}px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  display: inline-block;
  vertical-align: top;
  background: white;
  box-sizing: border-box;
`;

const Right = styled.div`
  width: calc(100vw - ${leftWidth}px);
  box-sizing: border-box;
  height: 100vh;
  display: inline-block;
  vertical-align: top;
  padding-left: 50px;
  padding-right: 50px;
`;

const FormPlaceholder = styled.div`

`;
