import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import * as lessonsActions from '../../../redux/actions/LessonsActions'

import {RedButton} from "../../ira/ui/Buttons";
import CoursesUnitsSelectorPanel from "../../courses/panels/CoursesUnitsSelectorPanel";
import IraLessonUnitsSelectorPanel from "./IraLessonUnitsSelectorPanel";
import SmartExercisesSelectorPanel from "../../smart_exercises/panels/SmartExercisesSelectorPanel";


const TABS = [
    {
        value: 'units',
        label: 'Модули'
    },
    {
        value: 'exercises',
        label: 'Упражнения'
    },
    {
        value: 'tests',
        label: 'Тесты'
    }

]

export default function IraEditLessonContentPanel(props) {
    const {
        id, onUpdated = () => {

        }
    } = props;
    let {currentUser, loading, lesson} = useMappedState(useCallback(state => {
        let lesson = state.lessons.lessonsMap.get(id);
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lesson: lesson
        }
    }, [id]));
    const dispatch = useDispatch();
    const [mode, setMode] = useState('units');

    useEffect(() => {
        dispatch(lessonsActions.loadLessonById(id));
    }, [id]);

    if (lesson == undefined) {
        return null;
    }
    let {exercisesIds = [], unitsIds = [], testsIds = []} = lesson;


    return (
        <Wrapper>
            <TopPlaceholder>
                <TopLeftPlaceholder>
                    {TABS.map((tab, i) => {
                        let isSelected = (mode == tab.value);
                        return (
                            <TabItem key={tab.value} selected={isSelected} onClick={() => {
                                setMode(tab.value)
                            }}>
                                {tab.label}
                            </TabItem>
                        )
                    })}
                </TopLeftPlaceholder>
                <TopRightPlaceholder>
                    <RedButton onClick={() => {
                        onUpdated();
                    }}>
                        СОХРАНИТЬ
                    </RedButton>
                </TopRightPlaceholder>
            </TopPlaceholder>

            <ContentPlaceholder>

                {mode != 'units' ? null : <IraLessonUnitsSelectorPanel id={id} {...lesson}
                                                                       onChange={async newIds => {
                                                                           await dispatch(lessonsActions.updateLesson({
                                                                               id: id,
                                                                               unitsIds: newIds
                                                                           }));
                                                                           onUpdated();
                                                                       }}/>
                }

                {mode != 'exercises' ? null :
                    <SmartExercisesSelectorPanel
                        {...lesson}
                        onChange={async newIds => {
                            await dispatch(lessonsActions.updateLesson({
                                id: id,
                                exercisesIds: newIds
                            }));
                            onUpdated();
                        }}
                    />
                }


            </ContentPlaceholder>


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const TopPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 31px;
`;

const ContentPlaceholder = styled.div`

`;

const TopLeftPlaceholder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 80px;
  background: #FFFFFF;
  border: 1px solid rgba(31, 32, 65, 0.25);
  box-sizing: border-box;
  border-radius: 4px;
`;

const TabItem = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 24px;
  background: ${props => (props.selected == true ? '#A36EFF' : 'white')};
  color: ${props => (props.selected == true ? '#FFFFFF' : '#333333')};

  :hover {

  }
`;


const TopRightPlaceholder = styled.div`

`;


const TopTableHeading = styled.div`
  background: #FFA71B;
  border-radius: 4px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #202224;
  box-sizing: border-box;
  padding-right: 31px;
`;

const StudentRow = styled.div`
  padding-top: 27px;
  padding-bottom: 27px;
  border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 31px;
  box-sizing: border-box;
  background: ${props => (props.selected != true ? 'white' : 'linear-gradient(0deg, #EDF8FF, #EDF8FF), #FFFFFF;')};
`;

const SelCol = styled.div`
  width: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
`;

const NameCol = styled.div`
  width: 400px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const FromCol = styled.div`
  width: 300px;
  box-sizing: border-box;
  padding-left: 31px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const OptionsCol = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

