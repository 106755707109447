import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

import * as teacherGroupsActions from '../../../redux/actions/TeacherGroupsActions'
import * as lessonsActions from '../../../redux/actions/LessonsActions'

import {RedButton, BlueButton, GreenButton} from "../../ira/ui/Buttons";
import IraEditLessonContentPanel from "./IraEditLessonContentPanel";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UnitView from "../../units/view/UnitView";
import FacesListTool from "../../teacher_groups/tools/FacesListTool";

let isTimeToday = t => {
    let from = +moment().startOf('day');
    let to = +moment().endOf('day');
    return (+t >= +from && +t < to);
}

export default function IraUserLessonsPanel(props) {
    const {
        userId,
        pageSize = 8
    } = props;
    let {currentUser, loading, lessons, groupsMap, uId, teacherGroup, units, getGroupById, getUsersByIds} = useMappedState(useCallback(state => {
        let uId = userId || state.users.currentUserId;
        let allTeacherGroups = state.teacherGroups.teacherGroupsMap.toArray();
        let studentGroups = allTeacherGroups.filter(a => ((a.studentsIds != undefined) && (a.studentsIds.indexOf(uId) > -1)));
        let groupsSet = studentGroups.reduce((st, a) => st.add(a.id), Set());
        let studentLessons = state.lessons.lessonsMap.toArray().filter(a => groupsSet.has(a.teacherGroupId)).sort((a, b) => (+b.startTimestamp - +a.startTimestamp));
        return {
            lessons: studentLessons,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            uId: uId,
            studentGroups: studentGroups,
            user: state.users.usersMap.get(uId),
            getGroupById: grId => state.teacherGroups.teacherGroupsMap.get(grId),
            getUsersByIds: ids => ids.map(a => state.users.usersMap.get(a)).filter(a => (a != undefined))
        }
    }, [userId]));
    const dispatch = useDispatch();
    const [shownNumber, setShownNumber] = useState(pageSize);

    const [tasksModalVisible, setTasksModalVisible] = useState(false);
    const [selectedUnitId, setSelectedUnitId] = useState();

    useEffect(() => {
        dispatch(lessonsActions.loadAllLessons()); // todo: optimize
        dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(uId));
    }, [uId]);

    const visibleLessons = lessons.slice(0, shownNumber);
    let restNumber = Math.max(0, lessons.length - visibleLessons.length);

    return (
        <Wrapper>

            <TopNavPlaceholder>
                <MidHeading>
                    Полное расписание всех занятий
                </MidHeading>

            </TopNavPlaceholder>

            <GroupsList>

                {visibleLessons.map((g, k) => {
                    let isToday = isTimeToday(g.startTimestamp);
                    let {name, minRating, maxRating, startTimestamp} = g;
                    let teacherGroup = getGroupById(g.teacherGroupId);
                    let studentsIds = (teacherGroup == undefined) ? [] : teacherGroup.studentsIds;
                    let students = getUsersByIds(studentsIds == undefined ? [] : studentsIds);
                    let latestLessonText = `Не создано`; // todo: implement
                    let groupLevelText = `${minRating == undefined ? '' : minRating} - ${maxRating == undefined ? '' : maxRating}`;
                    return (
                        <Box key={g.id} onClick={() => {

                        }}>
                            <BoxTop onClick={() => {
                                if (teacherGroup == undefined) {
                                    return;
                                }
                                CommonHelper.linkTo(`/group/${teacherGroup.id}`);
                            }}>
                                <BoxGroupImagePlaceholder>
                                    <PeopleImg src={require('../../../assets/images/people.svg')}/>
                                </BoxGroupImagePlaceholder>
                                <BoxTopName>
                                    {teacherGroup == undefined ? '' : teacherGroup.name}
                                </BoxTopName>
                            </BoxTop>

                            <FacesPlaceholder>
                                {students.length == 0 ?
                                    <VoidStudentsPlaceholder>
                                        Нет участников
                                    </VoidStudentsPlaceholder>
                                    :
                                    <FacesListTool faces={students}/>
                                }
                            </FacesPlaceholder>

                            <GroupInfoPlaceholder>

                                <GroupInfoRow>
                                    <img src={require('../../../assets/images/last_task_img.svg')}
                                         style={{width: 20, height: 22, marginRight: 10}}/>
                                    <GroupInfoSpan style={{marginRight: 10}}>
                                        Занятие:
                                    </GroupInfoSpan>
                                    <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                                        {moment(startTimestamp).format('DD.MM.YYYY HH:mm')}
                                    </GroupInfoSpan>
                                </GroupInfoRow>

                                {/*<GroupInfoRow>*/}
                                {/*<img src={require('../../../assets/images/group_level_img.svg')}*/}
                                {/*style={{width: 21, height: 27, marginRight: 10}}/>*/}
                                {/*<GroupInfoSpan style={{marginRight: 10}}>*/}
                                {/*Уровень:*/}
                                {/*</GroupInfoSpan>*/}
                                {/*<GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>*/}
                                {/*{groupLevelText}*/}
                                {/*</GroupInfoSpan>*/}
                                {/*</GroupInfoRow>*/}

                            </GroupInfoPlaceholder>

                            <GroupActionsPlaceholder>
                                {isToday == true ?
                                    <GreenButton onClick={() => {
                                        if (teacherGroup == undefined) {
                                            return;
                                        }
                                        CommonHelper.linkTo(`/online/${g.id}`);
                                    }}>
                                        ПЕРЕЙТИ К УРОКУ
                                    </GreenButton>
                                    :
                                    <BlueButton onClick={() => {
                                        if (teacherGroup == undefined) {
                                            return;
                                        }
                                        CommonHelper.linkTo(`/online/${g.id}`);
                                    }}>
                                        ПЕРЕЙТИ К УРОКУ
                                    </BlueButton>
                                }

                            </GroupActionsPlaceholder>

                        </Box>

                    )
                })}

                {restNumber <= 0 ? null :
                    <Box style={{
                        background: `#86D1FF`,
                        boxShadow: '0px 5px 10px rgba(31, 32, 65, 0.05)',
                        borderRadius: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer'
                    }} onClick={() => {
                        setShownNumber(shownNumber + pageSize);
                    }}>

                        <RefreshImg src={require('../../../assets/images/refresh_df.svg')}/>

                        <LoadMoreMainText>
                            Загрузить еще
                        </LoadMoreMainText>

                        <LoadMoreSubText>
                            {`${restNumber} уроков`}
                        </LoadMoreSubText>

                    </Box>
                }

            </GroupsList>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const RefreshImg = styled.img`
    width: 82px;
    height: 82px;
`;

const LoadMoreMainText = styled.div`
    font-size: 24px;
    line-height: 42px;
    color: #333333;
    text-align: center;
    margin-top: 35px;
`;

const LoadMoreSubText = styled.div`
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.0444444px;
    color: #333333;
    text-align: center;
    margin-top: 7px;
`;

const VoidStudentsPlaceholder = styled.div`
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.7);
`;

const GroupInfoPlaceholder = styled.div`
    margin-top: 20px;
    margin-bottom: 43px;
`;

const GroupActionsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const GroupsList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const TopNavPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
`;


const MidHeading = styled.div`
    font-size: 24px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.166667px;
    color: #333539;
`;


const Box = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 30px;
    width: calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    margin-bottom: 30px;
    margin-top: 0px;
    margin-right: 30px;
    
    //cursor: pointer;
    
    :nth-of-type(3n){
      margin-right: 0px;
    }
    //:hover{
    //  opacity: 0.8;
    //}
`;

const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
      text-decoration: underline;
    }
`;

const BoxGroupImagePlaceholder = styled.div`
    background: #A36EFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
    border-radius: 32px;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BoxTopName = styled.div`
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: #1F2041;
    cursor: pointer;
`;

const PeopleImg = styled.img`
    width: 31px;
`;

const FacesPlaceholder = styled.div`
    margin-top: 18px;
`;

const GroupInfoSpan = styled.span`
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.5);
`;

const GroupInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    :first-of-type{
      margin-bottom: 17px;
    }
    @media(max-width: 1220px){
      font-size: 14px;
    }
`;
