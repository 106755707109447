import axios from 'axios'
import {API_ENDPOINT} from "../constants/config";

const CoursesAPI = {

    getAllCourses() {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/courses/all`).then(d => d.data).then(courses => {
                if (courses.error != undefined) {
                    return reject(courses.error);
                }
                resolve(courses);
            }).catch(err => reject(err));
        });
    },

    async getStudentCourses(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/courses`)).data.result;
        return pld;
    },

    getCourse(id) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/course/${id}`).then(d => d.data).then(course => {
                if (course.error != undefined) {
                    return reject(course.error);
                }
                resolve(course);
            }).catch(err => reject(err));
        });
    },

    createCourse(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/courses/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateCourse(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/courses/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteCourse(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/courses/smart_delete`, {
                id: id
            }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    // units

    async getStudentUnits(userId) {
        let pld = (await axios.get(`${API_ENDPOINT}/user/${userId}/units`)).data.result;
        return pld;
    },

    getCourseUnits(courseId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/units/course/${courseId}`).then(d => d.data).then(units => {
                if (units.error != undefined) {
                    return reject(units.error);
                }
                resolve(units);
            }).catch(err => reject(err));
        });
    },

    loadAllUnits(courseId) {
        return new Promise((resolve, reject) => {
            axios.get(`${API_ENDPOINT}/units/all`).then(d => d.data).then(units => {
                if (units.error != undefined) {
                    return reject(units.error);
                }
                resolve(units);
            }).catch(err => reject(err));
        });
    },

    createUnit(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/units/create`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    updateUnit(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/units/update`, data).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },

    deleteUnit(id) {
        return new Promise((resolve, reject) => {
            axios.post(`${API_ENDPOINT}/units/smart_delete`, {
                id: id
            }).then(d => d.data).then(ex => {
                if (ex.error != undefined) {
                    return reject(ex.error);
                }
                resolve(ex);
            }).catch(err => reject(err));
        });
    },


}

export default CoursesAPI;
