import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';

import {DARK_SIDE} from "../ui/Colors";

import CommonHelper from '../../helpers/CommonHelper'

import Spin from 'arui-feather/spin'
import BottomUserPanel from "./BottomUserPanel";

import Sidebar from 'arui-feather/sidebar'

export default function MySidebar(props) {
    let {
        currentUser, loading, active, onLogout = () => {
        },
        onCurrentUserClick = () => {

        },
        onUserUpdate = updData => {

        }
    } = props;
    if (currentUser == undefined) {
        return null;
    }
    let {userRole} = currentUser;
    let options = [];
    if (userRole == 'admin') {
        options = adminOptions;
    }
    if (userRole == 'student') {
        options = studentsOptions;
    }
    if (userRole == 'teacher') {
        options = teacherOptions;
    }
    if (userRole == 'franchisee') {
        options = franchiseeOptions;
    }

    return (
        <Wrapper>

            <LogoPlaceholder>
                <LogoImage src={require('../../assets/images/bishop_logo.svg')}/>
                <LogoText>
                    ШАГИ
                </LogoText>
            </LogoPlaceholder>

            <OptionsList>
                {options.map((opt, k) => {
                    let {label, icon, url, name} = opt;
                    let isSelected = (active == name);
                    return (
                        <OptionItem selected={isSelected} key={k} onClick={() => {
                            CommonHelper.linkTo(url);
                        }}>
                            {label}
                        </OptionItem>
                    )
                })}

                <OptionItem style={{position: 'absolute', bottom: 70, left: 0, right: 0}} onClick={() => {
                    // onLogout();
                }}>
                    {/*{loading == false ?*/}
                    {/*<span>*/}
                    {/*Выход*/}
                    {/*</span>*/}
                    {/*:*/}
                    {/*<Spin visible={true} />*/}
                    {/*}*/}

                    <BottomUserPanel user={currentUser} onClick={() => {
                        onCurrentUserClick();
                    }} canUpdateAvatar={true} onUpdate={updData => {
                        onUserUpdate(updData);
                    }}/>

                </OptionItem>

                <OptionItem style={{
                    position: 'absolute', bottom: 10,
                    left: 0, right: 0, display: 'flex', flexDirect: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    boxSizing: 'border-box',
                    paddingRight: 15,
                    fontSize: 12,
                    cursor: 'default'

                }}>

                    {currentUser == undefined ? null :
                        <span style={{opacity: 0.5}}>
                            {CommonHelper.getRuNameOfRole(currentUser.userRole)}
                        </span>
                    }

                    {loading == false ?
                        <span style={{cursor: 'pointer'}} onClick={() => {
                            onLogout();
                        }}>
                        Выйти
                    </span>
                        :
                        <Spin visible={true}/>
                    }


                </OptionItem>

            </OptionsList>

        </Wrapper>
    );
}

const topHeight = 30;

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding-top: 10px;
`;

const LogoPlaceholder = styled.div`
    height: ${topHeight}px;
    line-height: ${topHeight}px;
    box-sizing: border-box;
    padding-left: 20px;
`;

const LogoImage = styled.img`
    display: inline-block;
    vertical-align: top;
    height: 100%;
    margin-right: 10px;
`;

const LogoText = styled.div`
    display: inline-block;
    vertical-align: top;
    color: white;
    opacity: 0.9;
    font-size: 20px;
    line-height: ${topHeight}px;
    height: ${topHeight}px;
`;

const OptionsList = styled.div`
    position: relative;
    height: calc(100% - ${topHeight}px);
    width: 100%;
    box-sizing: border-box;
    padding-top: 20px;
`;

const OptionItem = styled.div`
    line-height: 32px;
    height: 32px;
    cursor: ${props => (props.selected == true ? 'default' : 'pointer')};
    color: white;
    opacity: 0.9;
    width: 100%;
    padding-left: 20px;
    background: ${props => (props.selected == true ? DARK_SIDE : 'transparent')};
    box-sizing: border-box;
    :hover{
      opacity: 1;
    }
`;

const adminOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'franchisees',
        label: 'Франчайзи',
        url: '/franchisees'
    },
    {
        name: 'schools',
        label: 'Школы',
        url: '/schools'
    },
    {
        name: 'students',
        label: 'Ученики',
        url: '/students'
    },
    {
        name: 'teachers',
        label: 'Преподаватели',
        url: '/teachers'
    },
    {
        name: 'courses',
        label: 'Курсы',
        url: '/courses'
    },
    {
        name: 'exercises',
        label: 'Упражнения',
        url: '/exercises'
    },
    {
        name: 'documents',
        label: 'Документы',
        url: '/documents'
    }
];

const franchiseeOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    {
        name: 'schools',
        label: 'Школы',
        url: '/schools'
    },
    // {
    //     name: 'students',
    //     label: 'Ученики',
    //     url: '/students'
    // },
    // {
    //     name: 'teachers',
    //     label: 'Преподаватели',
    //     url: '/teachers'
    // },
    {
        name: 'courses',
        label: 'Курсы',
        url: '/courses'
    },
    {
        name: 'documents',
        label: 'Документы',
        url: '/documents'
    }
];

const teacherOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },
    // {
    //     name: 'students',
    //     label: 'Ученики',
    //     url: '/students'
    // },

    {
        name: 'courses',
        label: 'Курсы',
        url: '/courses'
    },
    {
        name: 'documents',
        label: 'Документы',
        url: '/documents'
    }
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];

const studentsOptions = [
    {
        name: 'index',
        label: 'Главная',
        url: '/'
    },

    // {
    //     name: 'schedule',
    //     label: 'Расписание',
    //     url: '/schedule'
    // },
    // {
    //     name: 'settings',
    //     label: 'Настройки',
    //     url: '/settings'
    // },
];
