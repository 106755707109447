import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import CommonHelper from "../../../helpers/CommonHelper";

import useDebouncedCallback from 'use-debounce/lib/callback'

import {Input} from "../../ui/Input";
import PaginatorWrapper from "../../paginator/PaginatorWrapper";

const getFranchisee = (frId, allFranchisees) => {
    let arr = allFranchisees.filter(a => (a.id == frId));
    if (arr.length == 0) {
        return null;
    }
    return arr[0];
}


export default function AdminAllStudentsPanel(props) {
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState('');
    let {currentUser, loading, schools, userRole, franchisees, students} = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        let franchisees = state.users.usersMap.toArray().filter(u => (u.userRole == 'franchisee'));
        let students = state.users.usersMap.toArray().filter(a => (a.userRole == 'student'))
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.users.loading,
            schools: state.schools.schoolsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)),
            franchisees: franchisees,
            students: students
        }
    }, []));
    const [debouncedSearch] = useDebouncedCallback((a) => {
        console.log('will set ', a);
        setSearchString(a);
    }, 300, []);
    if (currentUser == undefined || userRole != 'admin') {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    const sortedStudents = students.filter(a => {
        let {firstName, lastName, email} = a;
        let s = `${lastName} ${firstName} ${email}`;
        if (searchString == undefined || searchString.trim() == '') {
            return true;
        }
        return (s.toLowerCase().indexOf(searchString.toLowerCase()) > -1);
    }).sort((a, b) => {
        // let s1 = a.schoolId;
        // let s2 = a.schoolId;
        let s1 = `${a.lastName} ${a.firstName}`;
        let s2 = `${b.lastName} ${b.firstName}`;
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });

    return (
        <Wrapper>

            <Heading>
                Ученики ({students.length})
            </Heading>

            <TopSearchPlaceholder>
                <Input placeholder={'Поиск'} style={{
                    height: 36,
                    lineHeight: `36px`,
                    fontSize: 16
                }} onChange={evt => {
                    debouncedSearch(evt.target.value);
                }}/>
            </TopSearchPlaceholder>

            <PaginatorWrapper renderItems={items => items.map((c, k) => {
                let {id, firstName, lastName, schoolId, franchiseeId} = c;
                let fr = getFranchisee(franchiseeId, franchisees);
                let sc = getFranchisee(schoolId, schools);
                return (
                    <SchoolItem key={id} onClick={() => {
                        CommonHelper.linkTo(`/student/${id}`);
                    }}>
                        <Name>
                            {`${lastName} ${firstName}`}
                        </Name>
                        {fr == undefined ? null :
                            <FranchiseeInfoPlaceholder>
                                <FrImg src={require('../../../assets/images/manager-avatar.svg')}/>
                                <span>
                                        {`${fr.firstName} ${fr.lastName}`}
                                    </span>
                                {sc == undefined ? null :
                                    <span style={{marginLeft: 10}}>
                                            <FrImg src={require('../../../assets/images/university.svg')}/>
                                            <span>
                                                {`${sc.name}`}
                                            </span>
                                        </span>
                                }
                            </FranchiseeInfoPlaceholder>
                        }
                    </SchoolItem>
                )
            })} items={sortedStudents}/>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const SchoolsList = styled.div`
    
`;

const SchoolItem = styled.div`
    margin-bottom: 10px;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    :hover{
      opacity: 0.75;
    }
`;

const Name = styled.div`
    font-weight: bold;
`;

const FranchiseeInfoPlaceholder = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
`;

const FrImg = styled.img`
    height: 18px;
    margin-right: 5px;
`;

const Heading = styled.div`
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 10px;
    font-weight: bold;
`;

const TopSearchPlaceholder = styled.div`
    margin-bottom: 10px;
`;
