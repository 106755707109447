import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import useDebouncedCallback from "use-debounce/lib/callback";
import {LIGHT_BROWN} from "../../ui/Colors";

import Spin from 'arui-feather/spin'

import axios from 'axios'


import * as smartExercisesActions from '../../../redux/actions/SmartExercisesActions'
import * as exFoldersActions from '../../../redux/actions/ExFoldersActions'

import NiceModal from "../../modals/NiceModal";
import {Exercise} from "../../rubius";
import SmartExercisesAndFoldersViewTool from "../tools/SmartExercisesAndFoldersViewTool";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UpdateExFolderForm from "../forms/UpdateExFolderForm";
import UpdateShortExerciseForm from "../forms/UpdateShortExerciseForm";
import {BlueButton} from "../../ira/ui/Buttons";
import {API_ENDPOINT} from "../../../constants/config";
import CommonHelper from "../../../helpers/CommonHelper";
import SmartExercisesAPI from "../../../api/SmartExercisesAPI";
import {LOAD_SMART_EXERCISES_SUCCESS} from "../../../redux/ActionTypes";


export default function SmartExercisesAndFoldersPanel(props) {
    const {
        studentId, onExerciseClick = exId => {

        }
    } = props;

    const [studentExercises, setStudentExercises] = useState([]);
    const [studentFolders, setStudentFolders] = useState([]);

    let {
        currentUser,
        loading,
        smartExercises,
        userRole,
        isAdmin,
        currentUserId,
        exFolders
    } = useMappedState(useCallback(state => {
        let userRole = (currentUser != undefined) ? currentUser.userRole : undefined;
        return {
            userRole: userRole,
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            currentUserId: state.users.currentUserId,
            loading: state.smartExercises.loading || state.exFolders.loading,
            smartExercises: state.smartExercises.smartExercisesMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }),
            exFolders: state.exFolders.exFoldersMap.toArray().sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                if (a.name < b.name) {
                    return -1;
                }
                return 0;
            }),
            isAdmin: (userRole == 'admin' || userRole == 'methodist'),

        }
    }, []));
    const dispatch = useDispatch();

    const [selectedAddFolderId, setSelectedAddFolderId] = useState(undefined);
    const [selectedEditFolderId, setSelectedEditFolderId] = useState(undefined);
    const [addExerciseFolderId, setAddExerciseFolderId] = useState(undefined);
    const [moving, setMoving] = useState(false);

    const [moveData, setMoveData] = useState(undefined);

    const [targetFolderId, setTargetFolderId] = useState(undefined);

    let selectedEditFolder = (selectedEditFolderId == undefined) ? undefined : exFolders.filter(a => (a.id == selectedEditFolderId))[0];

    useEffect(() => {
        dispatch(exFoldersActions.loadAllExFolders());
        dispatch(smartExercisesActions.loadAllSmartExercises());
    }, []);

    useEffect(() => {
        if (studentId == undefined) {
            return;
        }
        SmartExercisesAPI.getUserExercises(studentId).then(pld => {
            setStudentExercises(pld.exercises);
            setStudentFolders(pld.folders);
            dispatch({type: LOAD_SMART_EXERCISES_SUCCESS, smartExercises: pld.exercises});
        });
    }, [studentId]);

    if (currentUser == undefined) {
        return (
            <Wrapper>
                Access denied
            </Wrapper>
        )
    }

    let addExerciseFolder = (addExerciseFolderId == undefined) ? undefined : exFolders.filter(a => (a.id == addExerciseFolderId))[0];

    console.log('SmartExercisesAndFoldersPanel: render: studentFolders = ', studentFolders);
    console.log('SmartExercisesAndFoldersPanel: render: studentExercises = ', studentExercises);

    return (
        <Wrapper>

            <SmartExercisesAndFoldersViewTool
                noFoldersSelectionName={(studentId == undefined) ? 'Папки' : 'Мои упражнения'}
                exFolders={(studentId == undefined) ? exFolders : studentFolders}
                smartExercises={(studentId == undefined) ? smartExercises : studentExercises}
                onFolderAdd={aId => {
                    setSelectedAddFolderId(aId == undefined ? '' : aId);
                }}
                onFolderEdit={aId => {
                    setSelectedEditFolderId(aId);
                }}
                onExerciseAdd={fId => {
                    setAddExerciseFolderId(fId == undefined ? '' : fId);
                }}
                onMove={(exercisesIds, foldersIds, fromFolderId) => {
                    setMoveData({
                        exercisesIds: exercisesIds,
                        foldersIds: foldersIds,
                        fromFolderId: fromFolderId
                    });
                }}
                onExerciseClick={exId => {
                    if (studentId == undefined) {
                        CommonHelper.linkTo(`/exercise/${exId}`, true);
                    } else {
                        onExerciseClick(exId);
                    }

                }}
                canDeleteFolder={isAdmin}
                hasControls={isAdmin}
                canAddExercise={isAdmin}
                canSelectFolder={isAdmin}
                canSelectExercise={isAdmin}
                onFolderDelete={async fId => {
                    await dispatch(exFoldersActions.deleteExFolder(fId));
                }}
            />

            {selectedAddFolderId == undefined ? null :
                <NiceModalWithHeading heading={'Добавление папки'} width={720}
                                      subHeading={'Укажите данные для создания папки'}
                                      onClose={() => {
                                          setSelectedAddFolderId(undefined);
                                      }}>

                    <UpdateExFolderForm loading={loading} onSave={async d => {
                        let data = {
                            ...d,
                            userId: currentUserId,
                            parentId: (selectedAddFolderId == '') ? undefined : selectedAddFolderId
                        };
                        await dispatch(exFoldersActions.createExFolder(data));
                        setSelectedAddFolderId(undefined);
                    }}/>

                </NiceModalWithHeading>
            }

            {selectedEditFolder == undefined ? null :
                <NiceModalWithHeading heading={'Редактирование папки'} width={720}
                                      subHeading={'Отредактируйте папку'}
                                      onClose={() => {
                                          setSelectedEditFolderId(undefined);
                                      }}>

                    <UpdateExFolderForm {...selectedEditFolder} loading={loading} onSave={async d => {
                        let data = {
                            ...d,
                            id: selectedEditFolder.id
                        };
                        await dispatch(exFoldersActions.updateExFolder(data));
                        setSelectedEditFolderId(undefined);
                    }}/>

                </NiceModalWithHeading>
            }

            {addExerciseFolderId == undefined ? null :
                <NiceModalWithHeading heading={'Добавление упражнени'} width={720}
                                      subHeading={'Дайте упражнению название'}
                                      onClose={() => {
                                          setAddExerciseFolderId(undefined);
                                      }}>

                    <UpdateShortExerciseForm loading={loading}
                                             {...addExerciseFolder}
                                             onSave={async d => {
                                                 let data = {
                                                     ...d,
                                                     foldersIds: (addExerciseFolderId == '') ? [] : [addExerciseFolderId]
                                                 };
                                                 await dispatch(smartExercisesActions.createSmartExercise(data));
                                                 setAddExerciseFolderId(undefined);
                                             }}/>

                </NiceModalWithHeading>
            }

            {moveData == undefined ? null :
                <NiceModalWithHeading heading={'Перемещение'} width={720}
                                      subHeading={'Укажите куда переместить выбранные элементы'}
                                      onClose={() => {
                                          setMoveData(undefined);
                                      }}>

                    <div style={{maxHeight: '80vh', overflowY: 'auto', paddingBottom: 20}}>
                        <SmartExercisesAndFoldersViewTool
                            showExercises={false}
                            canSelectMultiple={false}
                            canAddExercise={false}
                            exFolders={exFolders}
                            hasControls={false}
                            onFolderSelect={a => {
                                setTargetFolderId(a);
                            }}
                        />

                        {targetFolderId == undefined ? null :
                            <div style={{marginTop: 20}}>
                                {(moving || loading) ? <Spin visible={true}/> :
                                    <BlueButton
                                        onClick={async () => {
                                            let d = {...moveData, toFolderId: targetFolderId};
                                            await axios.post(`${API_ENDPOINT}/ex_folders/move_content`, d);
                                            await dispatch(smartExercisesActions.loadAllSmartExercises()); //todo: optimize
                                            await dispatch(exFoldersActions.loadAllExFolders()); // todo: opt
                                            setTargetFolderId(undefined);
                                            setMoveData(undefined);
                                        }}>
                                        Переместить в эту папку
                                    </BlueButton>
                                }

                            </div>
                        }
                    </div>

                </NiceModalWithHeading>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
