import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import {RedButton} from "../../ira/ui/Buttons";

import ls from 'local-storage'

export default function SmartExercisesAndFoldersViewTool(props) {
    const {
        noFoldersSelectionName = 'Папки',
        onExerciseAdd = folderId => {

        },
        onFolderAdd = folderId => {

        },
        onFolderDelete = folderId => {

        },
        onFolderEdit = folderId => {

        },
        onMove = (exercisesIds, foldersIds, currentFolderId) => {

        },

        selectedExercisesIds: initSelectedExercisesIds = [],

        showExercises = true,
        canSelectMultiple = true,
        canAddExercise = true,
        hasControls = true,
        canSelectFolder = true,
        canSelectExercise = true,

        canDeleteFolder = false,

        onFolderSelect = () => {

        },
        onExerciseClick = exId => {

        },

        onSelectedExercisesIdsChange = newIds => {

        }

    } = props;

    const [selectedExercisesIdsSet, setSelectedExercisesIdsSet] = useState(initSelectedExercisesIds.reduce((st, exId) => st.add(exId), Set()));
    const [selectedFoldersIdsSet, setSelectedFoldersIdsSet] = useState(Set());

    const {smartExercises = [], exFolders = []} = props;
    const [currentFolderId, setCurrentFolderId] = useState(ls(`SmartExercisesAndFoldersViewTool_currentFolderId`));

    useEffect(() => {
        if (currentFolderId != undefined) {
            ls(`SmartExercisesAndFoldersViewTool_currentFolderId`, currentFolderId);
        } else {
            ls.remove('SmartExercisesAndFoldersViewTool_currentFolderId');
        }
    }, [currentFolderId]);

    const visibleFolders = exFolders.filter(a => (a.parentId == currentFolderId)).sort((a, b) => {
        if (a.name > b.name) {
            return 1;
        }
        if (a.name < b.name) {
            return -1;
        }
        return 0;
    });

    const visibleExercises = smartExercises
        .filter(a => ((a.foldersIds == undefined ? [] : a.foldersIds).indexOf(currentFolderId) > -1 || ((a.foldersIds == undefined || a.foldersIds.length == 0) && currentFolderId == undefined)))
        .sort((a, b) => {
            if (a.name > b.name) {
                return 1;
            }
            if (a.name < b.name) {
                return -1;
            }
            return 0;
        });

    let currentFolder = (currentFolderId == undefined) ? undefined : exFolders.filter(a => (a.id == currentFolderId))[0];

    let canEditFolder = (selectedFoldersIdsSet.toArray().length == 1);
    let editFolderId = (selectedFoldersIdsSet.toArray().length == 0) ? undefined : selectedFoldersIdsSet.toArray()[0];

    let selectedExercisesIds = selectedExercisesIdsSet.toArray();
    let selectedFoldersIds = selectedFoldersIdsSet.toArray();

    let moveEditIconActive = (selectedFoldersIds.length > 0 || selectedExercisesIds.length > 0);

    // let isOneFoldersSelected = (selectedExercisesIdsSet.toArray().length == 1);
    let canDeleteCurrentFolder = (canDeleteFolder == true && currentFolder != undefined && visibleFolders.length == 0 && visibleExercises.length == 0);

    console.log('render: currentFolder = ', currentFolder);


    return (
        <Wrapper>

            <TopPlaceholder>
                <TopLeftPlaceholder>
                    {currentFolder == undefined ? null :
                        <CurrFolderImg src={require('./images/mdi_folder.svg')} onClick={() => {
                            setCurrentFolderId(currentFolder.parentId);
                        }}/>
                    }
                    {currentFolder == undefined && noFoldersSelectionName == '' ? null :
                        <CurrFolderName>
                            {currentFolder == undefined ? noFoldersSelectionName : ` > ${currentFolder.name}`}
                        </CurrFolderName>
                    }
                </TopLeftPlaceholder>
                {hasControls == false ? null :
                    <TopRightPlaceholder>
                        {canAddExercise == false ? null :
                            <RedButton onClick={() => {
                                onExerciseAdd(currentFolderId);
                            }}>
                                Создать упражнение
                            </RedButton>
                        }
                    </TopRightPlaceholder>
                }

            </TopPlaceholder>

            {hasControls == false ? null :
                <TopControlsPlaceholder>
                    <ControlButton src={require('./images/folder-plus.svg')} enabled={true} onClick={() => {
                        onFolderAdd(currentFolderId);
                    }}/>

                    <ControlButton src={require('./images/corner-down-right.svg')} enabled={moveEditIconActive}
                                   onClick={() => {
                                       onMove(selectedExercisesIds, selectedFoldersIds, currentFolderId);
                                   }}/>
                    <ControlButton src={require('./images/edit.svg')} enabled={canEditFolder} onClick={() => {
                        onFolderEdit(editFolderId);
                    }}/>
                    <ControlButton src={require('./images/trash.svg')} enabled={canDeleteCurrentFolder} onClick={() => {
                        if (currentFolder != undefined) {
                            ls.remove('SmartExercisesAndFoldersViewTool_currentFolderId');
                            onFolderDelete(currentFolderId);
                            setCurrentFolderId(undefined);
                        }
                    }}/>
                </TopControlsPlaceholder>
            }


            <BottomItemsContentPlaceholder>

                {visibleFolders.map((item, i) => {
                    return (
                        <React.Fragment key={`${item.id}_${i}`}>
                            <SelectableBox
                                {...item}
                                selected={selectedFoldersIdsSet.has(item.id)}
                                name={item.name}
                                isFolder={true}
                                canSelect={canSelectFolder}
                                onGo={() => {
                                    setCurrentFolderId(item.id);
                                    if (canSelectFolder == true) {
                                        setSelectedExercisesIdsSet(Set());
                                    }
                                    setSelectedFoldersIdsSet(Set());
                                }}
                                onSelect={() => {
                                    let newSet = Set();
                                    if (selectedFoldersIdsSet.has(item.id)) {
                                        if (canSelectMultiple == true) {
                                            newSet = selectedFoldersIdsSet.delete(item.id);
                                        } else {
                                            newSet = (Set());
                                            onFolderSelect();
                                        }
                                    } else {
                                        if (canSelectMultiple == true) {
                                            newSet = selectedFoldersIdsSet.add(item.id);
                                        } else {
                                            newSet = (Set()).add(item.id);
                                            onFolderSelect(item.id);
                                        }
                                    }
                                    setSelectedFoldersIdsSet(newSet);
                                }}
                            />
                        </React.Fragment>
                    )
                })}

                {showExercises == false ? null :
                    <React.Fragment>
                        {visibleExercises.map((item, i) => {
                            return (
                                <React.Fragment key={`${item.id}_${i}`}>
                                    <SelectableBox
                                        {...item}
                                        selected={selectedExercisesIdsSet.has(item.id)}
                                        name={item.name}
                                        canSelect={canSelectExercise}
                                        isFolder={false}
                                        onGo={() => {
                                            onExerciseClick(item.id);
                                        }}
                                        onSelect={() => {
                                            let newSet = Set();
                                            if (selectedExercisesIdsSet.has(item.id)) {
                                                newSet = selectedExercisesIdsSet.delete(item.id);
                                            } else {
                                                newSet = selectedExercisesIdsSet.add(item.id);
                                            }
                                            setSelectedExercisesIdsSet(newSet);
                                            onSelectedExercisesIdsChange(newSet.toArray().sort());
                                        }}
                                    />
                                </React.Fragment>
                            )
                        })}
                    </React.Fragment>
                }

            </BottomItemsContentPlaceholder>


        </Wrapper>
    );
}


const Wrapper = styled.div`
    
`;

const BottomItemsContentPlaceholder = styled.div`
    
`;

const SelectableBox = (props) => {
    let {
        isFolder = false, onSelect = () => {

        }, onGo = () => {

        },
        canSelect = true,
        selected = false,
        name = '',
        fromRating = '',
        toRating = ''
    } = props;

    return (
        <SelectableBoxWrapper selected={selected}>

            {canSelect == false ? null :
                <CheckSide>
                    <ChSquareImg
                        src={(selected == true) ? require('./images/ch_box.svg') : require('./images/ch_void.svg')}
                        onClick={() => {
                            onSelect();
                        }}
                    />
                </CheckSide>
            }
            <SelBoxContent onClick={() => {
                onGo();
            }}>
                <SelectableBoxNamePlaceholder>
                    <SelBoxName>
                        {isFolder == false ? null :
                            <SelectableBoxFolderImg src={require('./images/mdi_folder_.svg')}/>
                        }
                        {name}
                    </SelBoxName>
                    <RatingPlaceholder>
                        {`${fromRating} - ${toRating}`}
                    </RatingPlaceholder>
                </SelectableBoxNamePlaceholder>
            </SelBoxContent>
        </SelectableBoxWrapper>
    )
}

const SelectableBoxWrapper = styled.div`
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid rgba(8, 91, 255, 0.1);
    background: ${props => (props.selected == false ? '#FFFFFF' : 'linear-gradient(0deg, rgba(134, 209, 255, 0.15), rgba(134, 209, 255, 0.15)), #FFFFFF;')};
    margin-bottom: 10px;
`;

const SelBoxName = styled.div`
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #202224;
    mix-blend-mode: normal;
    opacity: 0.9;
`;

const RatingPlaceholder = styled.div`
    
`;


const ChSquareImg = styled.img`
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

const SelectableBoxNamePlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const SelectableBoxFolderImg = styled.img`
    height: 32px;
    margin-right: 10px;
`;

const CheckSide = styled.div`
    width: 35px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding-top: 5px
`;

const SelBoxContent = styled.div`
    flex: 1;
    cursor: pointer;
`;

const CurrFolderImg = styled.img`
    height: 38px;
    margin-right: 24px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const CurrFolderName = styled.div`
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #2E384D;
`;

const ControlButton = (props) => {
    let {
        src, enabled = false, onClick = () => {

        }
    } = props;
    return (
        <ContCircle enabled={enabled} onClick={() => {
            if (enabled == true) {
                onClick();
            }
        }}>
            <ControlIcon src={src}/>
        </ContCircle>
    );
}

const ControlIcon = styled.img`
    height: 24px;
`;

const ContCircle = styled.div`
    background: #8593A8;
    box-sizing: border-box;
    border-radius: 22px;
    border: 2px solid #8593A8;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    opacity: ${props => (props.enabled == true ? 1 : 0.3)};
    cursor: ${props => (props.enabled == true ? 'pointer' : 'default')};
    margin-right: 10px;
`;

const TopControlsPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
`;

const TopPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`;

const TopLeftPlaceholder = styled.div`
    font-weight: 300;
    font-size: 28px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #2E384D;
`;

const TopRightPlaceholder = styled.div`
    
`;
