import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {HashRouter, Switch, Route} from 'react-router-dom'
import {useDispatch, useMappedState} from 'redux-react-hook';

import AdminIndexApp from './AdminIndexApp'
import DevApp from './DevApp'
import CourseApp from './CourseApp'
import CoursesApp from "./CoursesApp";
import AdminStudentsApp from "./AdminStudentsApp";
import AdminTeachersApp from "./AdminTeachersApp";
import StudentApp from "./StudentApp";
import AdminExercisesApp from "./AdminExercisesApp";
import AdminExercisesGroupsApp from "./AdminExercisesGroupsApp";
import ExerciseApp from "./ExerciseApp";
import StudentGroupsApp from "./StudentGroupsApp";
import TeacherGroupsApp from "./TeacherGroupsApp";
import TeacherGroupApp from "./TeacherGroupApp";
import StudentGroupApp from "./StudentGroupApp";
import OnlineLessonApp from "./OnlineLessonApp";
import TeacherLessonApp from "./TeacherLessonApp";
import UserLessonsApp from "./UserLessonsApp";
import UserSettingsApp from "./UserSettingsApp";
import TeacherSettingsApp from "./TeacherSettingsApp";
import AdminSettingsApp from "./AdminSettingsApp";
import AdminExerciseApp from "./AdminExerciseApp";
import UserHomeworkApp from "./UserHomeworkApp";

import TwoPlayApp from "./TwoPlayApp";

import UserLessonHomeworkApp from "./UserLessonHomeworkApp";

import SettingsApp from "./SettingsApp";

import FullPagePreloader from '../preloader/FullPagePreloader'

import * as teacherGroupsActions from '../../redux/actions/TeacherGroupsActions'
import * as usersActions from '../../redux/actions/UsersActions'
import * as coursesActions from '../../redux/actions/CoursesActions'
import * as lessonsActions from '../../redux/actions/LessonsActions'
import * as exercisesActions from '../../redux/actions/ExercisesActions'
import * as exercisesGroupsActions from '../../redux/actions/ExercisesGroupsActions'
import * as tagsActions from '../../redux/actions/TagsActions'
import * as testsActions from '../../redux/actions/TestsActions'
import * as foldersActions from '../../redux/actions/ExercisesFoldersActions'
import * as smartExercisesActions from '../../redux/actions/SmartExercisesActions'
import * as exFoldersActions from '../../redux/actions/ExFoldersActions'

import TeacherIndexApp from "./TeacherIndexApp";
import StudentIndexApp from "./StudentIndexApp";
import StudentExercisesApp from "./StudentExercisesApp";
import IraLoginApp from "./IraLoginApp";
import RecoverPasswordApp from "./RecoverPasswordApp";
import TeacherStudentsApp from "./TeacherStudentsApp";
import TeacherApp from "./TeacherApp";
import TournamentApp from "./TournamentApp";
import TournamentsApp from "./TournamentsApp";
import AdminTestsApp from "./AdminTestsApp";
import ConfApp from "./ConfApp";
import SharedBoardApp from "./SharedBoardApp";

import ChatkitAPI from '../../api/ChatkitAPI'
import AdminSmartExercisesApp from "./AdminSmartExercisesApp";
import TeacherScheduleApp from "./TeacherScheduleApp";
import StudentCoursesApp from "./StudentCoursesApp";
import StudentCourseApp from "./StudentCourseApp";
import HeadlessLessonApp from "./HeadlessLessonApp";
import StudentRatingApp from "./StudentRatingApp";

const adminRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>
        {/*<Route exact path='/settings' component={SettingsApp}/>*/}
        <Route exact path='/settings/:mode' component={AdminSettingsApp}/>
        <Route exact path='/settings' component={AdminSettingsApp}/>

        <Route exact path='/tests' component={AdminTestsApp}/>
        <Route exact path='/tournaments' component={TournamentsApp}/>
        <Route exact path='/tournament/:id' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId/user/:userId' component={TournamentApp}/>

        <Route exact path='/online/:id' component={OnlineLessonApp}/>

        <Route exact path='/play/:id' component={TwoPlayApp}/>

        <Route exact path='/course/:id' component={CourseApp}/>
        <Route exact path='/group/:id' component={TeacherGroupApp}/>
        <Route exact path='/courses' component={CoursesApp}/>
        <Route exact path='/teachers' component={AdminTeachersApp}/>
        <Route exact path='/students' component={AdminStudentsApp}/>
        <Route exact path='/exercises' component={AdminSmartExercisesApp}/>
        <Route exact path='/exercises_groups' component={AdminExercisesGroupsApp}/>
        <Route exact path='/student/:id' component={StudentApp}/>
        <Route exact path='/exercise/:id' component={AdminExerciseApp}/>
        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/conf/:room' component={ConfApp}/>
        <Route exact path='/shared/:room/board' component={SharedBoardApp}/>

        <Route component={AdminIndexApp}/>
    </Switch>
);

const methodistRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>
        {/*<Route exact path='/settings' component={SettingsApp}/>*/}
        <Route exact path='/settings/:mode' component={AdminSettingsApp}/>
        <Route exact path='/settings' component={AdminSettingsApp}/>

        <Route exact path='/course/:id' component={CourseApp}/>
        <Route exact path='/courses' component={CoursesApp}/>
        <Route exact path='/exercises' component={AdminSmartExercisesApp}/>
        <Route exact path='/exercises_groups' component={AdminExercisesGroupsApp}/>
        <Route exact path='/exercise/:id' component={AdminExerciseApp}/>
        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>

        <Route component={AdminIndexApp}/>
    </Switch>
);


const teacherRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>
        <Route exact path='/groups' component={TeacherGroupsApp}/>
        <Route exact path='/settings' component={TeacherSettingsApp}/>
        <Route exact path='/settings/:mode' component={TeacherSettingsApp}/>

        <Route exact path='/play/:id' component={TwoPlayApp}/>

        <Route exact path='/schedule' component={TeacherScheduleApp}/>

        <Route exact path='/lesson/:id' component={TeacherLessonApp}/>
        <Route exact path='/online/:id' component={OnlineLessonApp}/>

        <Route exact path='/tournaments' component={TournamentsApp}/>

        <Route exact path='/tournament/:id' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId/user/:userId' component={TournamentApp}/>

        <Route exact path='/students' component={TeacherStudentsApp}/>
        <Route exact path='/course/:id' component={CourseApp}/>
        <Route exact path='/group/:id' component={TeacherGroupApp}/>
        <Route exact path='/courses' component={CoursesApp}/>
        <Route exact path='/student/:id' component={StudentApp}/>

        <Route exact path='/exercise/:id' component={AdminExerciseApp}/>

        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>

        <Route exact path='/exercises' component={AdminSmartExercisesApp}/>

        <Route exact path='/conf/:room' component={ConfApp}/>
        <Route exact path='/shared/:room/board' component={SharedBoardApp}/>
        <Route component={TeacherIndexApp}/>
    </Switch>
);

const studentRoute = (
    <Switch>
        <Route exact path='/dev' component={DevApp}/>

        <Route exact path='/groups' component={StudentGroupsApp}/>
        <Route exact path='/homework' component={UserHomeworkApp}/>

        <Route exact path='/lessons' component={UserLessonsApp}/>
        <Route exact path='/lesson/:id/homework' component={UserLessonHomeworkApp}/>

        <Route exact path='/course/:id' component={StudentCourseApp}/>

        <Route exact path='/schedule' component={UserLessonsApp}/>

        <Route exact path='/play/:id' component={TwoPlayApp}/>

        <Route exact path='/settings' component={UserSettingsApp}/>
        <Route exact path='/settings/:mode' component={UserSettingsApp}/>

        <Route exact path='/online/:id' component={OnlineLessonApp}/>
        <Route exact path='/courses' component={StudentCoursesApp}/>

        <Route exact path='/rating' component={StudentRatingApp}/>
        <Route exact path='/tournaments' component={TournamentsApp}/>
        <Route exact path='/tournament/:id' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId/user/:userId' component={TournamentApp}/>

        <Route exact path='/student/:id' component={StudentApp}/>
        <Route exact path='/group/:id' component={StudentGroupApp}/>
        <Route exact path='/exercise/:id' component={ExerciseApp}/>

        <Route exact path='/exercises' component={StudentExercisesApp}/>

        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/conf/:room' component={ConfApp}/>
        <Route exact path='/shared/:room/board' component={SharedBoardApp}/>
        <Route component={StudentIndexApp}/>
    </Switch>
);


const guestRoute = (
    <Switch>
        <Route path="/dev" component={DevApp}/>
        <Route exact path='/settings' component={UserSettingsApp}/>

        <Route exact path='/exercise/:id' component={ExerciseApp}/>

        <Route exact path='/tournaments' component={TournamentsApp}/>

        <Route exact path='/play/:id' component={TwoPlayApp}/>

        <Route exact path='/tournament/:id' component={TournamentApp}/>

        <Route exact path='/headless/:id' component={HeadlessLessonApp}/>
        <Route exact path='/online/:id' component={OnlineLessonApp}/>

        <Route exact path='/tournament/:id/game/:gameId' component={TournamentApp}/>
        <Route exact path='/tournament/:id/game/:gameId/user/:userId' component={TournamentApp}/>

        <Route exact path='/group/:id' component={TeacherGroupApp}/>
        <Route exact path='/teacher/:id' component={TeacherApp}/>
        <Route exact path='/recover/:key' component={RecoverPasswordApp}/>
        <Route exact path='/conf/:room' component={ConfApp}/>
        <Route exact path='/shared/:room/board' component={SharedBoardApp}/>
        <Route exact path="*" component={IraLoginApp}/>
    </Switch>
)

export default function RouterApp(props) {
    const mapState = useCallback(
        state => ({
            initialized: state.users.initialized,
            currentUser: state.users.currentUserId == undefined ? null : state.users.usersMap.get(state.users.currentUserId)
        }), []
    );
    const {initialized, currentUser} = useMappedState(mapState);
    const dispatch = useDispatch();
    console.log('currentUser = ', currentUser);

    useEffect(() => {
        dispatch(usersActions.initializeAuthorization()).then(pld => {

            if (pld.user != undefined) {
                window.CURRENT_USER = pld.user;
                window.CURRENT_USER_ROLE = pld.user.userRole;
                console.log('global configs are set: CURRENT_USER = ', window.CURRENT_USER);
            }

            console.log('initializeAuthorization: pld = ', pld);
            //todo: optimize the code below, check creds on backend
            // if (pld.user != undefined && pld.user.userRole == 'admin') {
            //
            // }

            //todo: optimize code below
            dispatch(usersActions.loadAllUsers());
            dispatch(coursesActions.loadAllCourses());
            dispatch(coursesActions.loadAllUnits());
            dispatch(lessonsActions.loadAllLessons());
            dispatch(exercisesActions.loadAllExercises());
            dispatch(teacherGroupsActions.loadAllTeacherGroups());
            dispatch(exercisesGroupsActions.loadAllExercisesGroups());
            dispatch(tagsActions.loadAllTags());
            dispatch(foldersActions.loadAllExercisesFolders());
            dispatch(testsActions.loadAllTests());
            dispatch(smartExercisesActions.loadAllSmartExercises());

            dispatch(exFoldersActions.loadAllExFolders());

            // if (pld.user != undefined) {
            //     ChatkitAPI.init(pld.user.id).then(a => {
            //         console.log('chat has been initialized! a = ', a);
            //     })
            // }
        });
    }, []);

    if (initialized == false) {
        return (
            <FullPagePreloader visible={true}/>
        )
    }
    let isGuest = (currentUser == undefined);
    let isAdmin = (isGuest == false && currentUser.userRole == 'admin');
    let isTeacher = (isGuest == false && currentUser.userRole == 'teacher');
    let isStudent = (isGuest == false && currentUser.userRole == 'student');
    let isMethodist = (isGuest == false && currentUser.userRole == 'methodist');

    let route = isGuest == true ? guestRoute : (isAdmin == true ? adminRoute : null);
    if (isTeacher == true) {
        route = teacherRoute;
    }
    if (isStudent == true) {
        route = studentRoute;
    }
    if (isMethodist == true) {
        route = methodistRoute;
    }

    console.log('isAdmin = ', isAdmin);

    return (
        <Wrapper>

            <HashRouter>
                {route}
            </HashRouter>

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;
