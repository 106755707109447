import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input, Label} from "../../../ira/ui/Inputs";
import SimpleUploadPanel from "../../../upload/SimpleUploadPanel";
import {BlueButton} from "../../../ira/ui/Buttons";

export default function UpdateLandingWebinarCardForm(props) {

    const {
        onSave = d => {

        }
    } = props;

    const [name, setName] = useState(props.name == undefined ? '' : props.name);
    const [level, setLevel] = useState(props.level == undefined ? '' : props.level);
    const [price, setPrice] = useState(props.price == undefined ? '' : props.price);
    const [author, setAuthor] = useState(props.author == undefined ? '' : props.author);
    const [date, setDate] = useState(props.date == undefined ? '' : props.date);
    const [avatar, setAvatar] = useState(props.avatar);


    return (
        <Wrapper>

            <AvaPlaceholder>
                <SimpleUploadPanel onUploaded={url => {
                    setAvatar(url);
                }}>
                    <AvaWrap>
                        <AvaBox src={avatar}>
                            {avatar != undefined ? null :
                                <MaskImg src={require('../../../../assets/images/photo_mask.svg')}/>
                            }
                        </AvaBox>
                        <AvaLabel>
                            Загрузить обложку
                        </AvaLabel>
                    </AvaWrap>
                </SimpleUploadPanel>
            </AvaPlaceholder>

            <Row>
                <Field>
                    <Label>
                        Название
                    </Label>
                    <Input value={name} onChange={evt => {
                        setName(evt.target.value)
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Уровень
                    </Label>
                    <Input value={level} onChange={evt => {
                        setLevel(evt.target.value)
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Дата
                    </Label>
                    <Input value={date} onChange={evt => {
                        setDate(evt.target.value)
                    }}/>
                </Field>
            </Row>

            <Row>
                <Field>
                    <Label>
                        Автор
                    </Label>
                    <Input value={author} onChange={evt => {
                        setAuthor(evt.target.value)
                    }}/>
                </Field>
                <Field>
                    <Label>
                        Цена
                    </Label>
                    <Input value={price} onChange={evt => {
                        setPrice(evt.target.value)
                    }}/>
                </Field>
            </Row>

            <Field style={{marginTop: 30}} >
                <BlueButton onClick={() => {
                    onSave({
                        name,
                        price,
                        avatar,
                        level,
                        date,
                        author
                    });
                }}>
                    Сохранить
                </BlueButton>
            </Field>

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const MaskImg = styled.div`
    height: 21px;
`;

const Field = styled.div`
    margin-bottom: 15px;
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
`;


const AvaPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 40px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
`;

const Half = styled.div`
    flex: 1;
`;

const AvaWrap = styled.div`
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AvaBox = styled.div`
    width: 200px;
    height: 150px;
    background: #ECECEE;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url(${props => props.src});
`;

const AvaLabel = styled.div`
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-top: 16px;
    color: #085BFF;
`;

const AvaSectionPlaceholder = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
`;
