import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

const jitsiDivId = 'jitsi_placeholder';

const getUName = u => {
    if (u == undefined) {
        return `No name`;
    }
    let ie = x => (x == undefined || x == '');
    if (ie(u.firstName) || ie(u.lastName)) {
        return u.email;
    }
    return `${u.firstName} ${u.lastName}`;
}

export default function SimpleJitsiRoomTool(props) {
    const {roomId} = props;

    const jitsiRef = useRef();

    useEffect(() => {
        try {
            jitsiRef.current.api = new window.JitsiMeetExternalAPI(window.JITSI_SERVER_URL || 'jitsi.shagi.online', {
                parentNode: document.querySelector(`#${jitsiDivId}`),
                roomName: `room_${roomId}`,
                userInfo: {
                    email: (window.CURRENT_USER == undefined ? '' : window.CURRENT_USER.email),
                    displayName: getUName(window.CURRENT_USER)
                },
                muted: false,
                interfaceConfigOverwrite: {
                    disableDeepLinking: true,
                    SHOW_WATERMARK_FOR_GUESTS: false,
                    DEFAULT_REMOTE_DISPLAY_NAME: 'Собеседник',
                    DISABLE_VIDEO_BACKGROUND: true,
                    DISABLE_DOMINANT_SPEAKER_INDICATOR: true,
                    DISABLE_FOCUS_INDICATOR: true,
                    SHOW_BRAND_WATERMARK: true,
                    SHOW_JITSI_WATERMARK: false,
                    DEFAULT_LOGO_URL: 'https://www.englishpatient.org/english-patient-files/1f2e3795-a753-4275-81a5-820d1546879a.png',
                    DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://www.englishpatient.org/english-patient-files/1f2e3795-a753-4275-81a5-820d1546879a.png',
                    APP_NAME: 'ТГУ',
                    NATIVE_APP_NAME: 'ТГУ',
                    PROVIDER_NAME: 'ТГУ',
                    AUTHENTICATION_ENABLE: false,
                    MOBILE_APP_PROMO: false,
                    TOOLBAR_BUTTONS: [
                        'desktop', 'microphone', 'camera', 'tileview', 'fullscreen', 'fodeviceselection', 'raisehand',
                        'etherpad', 'settings', 'videoquality', 'filmstrip', 'shortcuts'
                    ],
                    SETTINGS_SECTIONS: ['devices']
                },
                configOverwrite: {
                    ignoreStartMuted: true,
                    muted: false,
                    disableAudioLevels: true,
                    disableDeepLinking: true,
                },


            });
        } catch (exc) {

        }

    }, []);

    return (
        <Wrapper id={jitsiDivId} >

        </Wrapper>
    );
}

const Wrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;