import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import SmartExercisesAPI from "../../../api/SmartExercisesAPI";
import SimpleRatingChart from "../charts/SimpleRatingChart";
import UpdateBaseRatingForm from "../forms/UpdateBaseRatingForm";

import * as actions from '../../../redux/actions/UsersActions'

export default function UserRatingPanel(props) {
    const {
        id,
        hasUserUpdateInfoTool = false
    } = props;

    const {
        tagName = undefined
    } = props;

    const dispatch = useDispatch();
    let {currentUser, student, reduxLoading} = useMappedState(useCallback(state => ({
        currentUser: state.users.usersMap.get(state.users.currentUserId),
        student: state.users.usersMap.get(id),
        reduxLoading: state.users.loading
    }), []));

    const [loading, setLoading] = useState(false);
    const [points, setPoints] = useState([]);

    const [updT, setUpdT] = useState(0);

    let currentUserRole = (currentUser == undefined) ? undefined : currentUser.userRole;


    useEffect(() => {
        setLoading(true);
        SmartExercisesAPI.getUserRatingsArray(id, tagName).then(arr => {
            setPoints(arr);
            setLoading(false);
        })
    }, [id]);

    if (points.length == 0 && loading == true) {
        return (
            <Code/>
        )
    }

    if (points.length == 0 || student == undefined) {
        return (
            <Wrapper>
                Нет данных
            </Wrapper>
        )
    }

    return (
        <Wrapper>
            {/*{JSON.stringify(points)}*/}

            <div key={updT}>
                <SimpleRatingChart {...student} points={points}/>
            </div>

            {hasUserUpdateInfoTool == false ? null :
                <>
                    {currentUserRole != 'teacher' || student == undefined ? null :
                        <div>
                            <UpdateBaseRatingForm {...student} loading={reduxLoading} onSave={async s => {
                                await dispatch(actions.updateUser({id: id, baseRating: +s}));
                                await timeout(1000);
                                setUpdT(+new Date());
                            }}/>
                        </div>
                    }
                </>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));