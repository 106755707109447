import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import CommonHelper from "../../../helpers/CommonHelper";

import * as teacherGroupsActions from '../../../redux/actions/TeacherGroupsActions'
import * as lessonsActions from '../../../redux/actions/LessonsActions'

import {RedButton, BlueButton} from "../../ira/ui/Buttons";
import IraEditLessonContentPanel from "./IraEditLessonContentPanel";
import BrutalOverlayModal from "../../modals/BrutalOverlayModal";
import NiceModalWithHeading from "../../modals/NiceModalWithHeading";
import UnitView from "../../units/view/UnitView";
import IllegalWorkspace from "../../rubius/exercise/Workspace/IllegalWorkspace";
import Workspace from "../../rubius/exercise/Workspace/Workspace";
import LessonExercisesResultsPanel from "../exercises_results/LessonExercisesResultsPanel";
import LessonAttendancePanel from "./LessonAttendancePanel";
import PgnViewerWrapper from "../../rubius/chessboard/PgnViewerWrapper/PgnViewerWrapper";
import Chessboard from "../../rubius/chessboard/Chessboard";
import CommunicationToolSwitcher from "../tools/CommunicationToolSwitcher";


const getUnitsByLessonId = (state, id) => {
    let lesson = state.lessons.lessonsMap.get(id);
    if (lesson == undefined) {
        return [];
    }
    let {unitsIds = []} = lesson;
    let getCourseName = cId => (state.courses.coursesMap.get(cId) == undefined ? '' : state.courses.coursesMap.get(cId).name);
    let units = unitsIds.map(a => state.courses.unitsMap.get(a)).filter(a => (a != undefined));
    return units.map(a => ({...a, courseName: getCourseName(a.courseId)}));
}

const getSortedListByName = arr => {
    return arr.sort((a, b) => {
        let s1 = (a == undefined || a.name == undefined) ? '' : a.name.toLowerCase();
        let s2 = (b == undefined || b.name == undefined) ? '' : b.name.toLowerCase();
        if (s1 > s2) {
            return 1;
        }
        if (s1 < s2) {
            return -1;
        }
        return 0;
    });
}

export default function TeacherLessonPanel(props) {
    const {
        id
    } = props;
    let {currentUser, loading, lesson, groupsMap, uId, teacherGroup, units, exercises} = useMappedState(useCallback(state => {
        let uId = state.users.currentUserId;
        let lesson = state.lessons.lessonsMap.get(id);
        let teacherGroup = (lesson == undefined) ? undefined : state.teacherGroups.teacherGroupsMap.get(lesson.teacherGroupId);
        let exercisesIds = (lesson == undefined || lesson.exercisesIds == undefined) ? [] : lesson.exercisesIds;
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.lessons.loading,
            lesson: state.lessons.lessonsMap.get(id),
            uId: uId,
            teacherGroup: teacherGroup,
            units: getSortedListByName(getUnitsByLessonId(state, id)),
            exercises: getSortedListByName(exercisesIds.map(exId => state.smartExercises.smartExercisesMap.get(exId)).filter(a => (a != undefined)))
        }
    }, [id]));
    const dispatch = useDispatch();
    const [updT, setUpdT] = useState(0);
    const [tasksModalVisible, setTasksModalVisible] = useState(false);
    const [selectedUnitId, setSelectedUnitId] = useState();
    const [selectedExerciseId, setSelectedExerciseId] = useState(undefined);
    const [isPreviewingExercise, setIsPreviewingExercise] = useState(false);

    useEffect(() => {
        dispatch(lessonsActions.loadAllLessons()); // todo: optimize
        dispatch(teacherGroupsActions.loadAllTeacherGroupsOfTeacher(uId));
    }, [uId]);

    if (lesson == undefined || teacherGroup == undefined) {
        return (
            <Code/>
        )
    }

    const viewUnit = (selectedUnitId == undefined) ? undefined : units.filter(a => (a.id == selectedUnitId))[0];
    const viewExercise = (selectedExerciseId == undefined) ? undefined : exercises.filter(a => (a.id == selectedExerciseId))[0];

    // console.log('--->>> render: exercises = ', exercises);
    // console.log('--->>> render: units = ', units);

    console.log('TeacherLessonPanel: render: lesson = ', lesson);

    return (
        <Wrapper>

            <HorHeading>
                <TopLeft>

                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <WrapperInner>
                <TopNavPlaceholder>
                    <MidHeading onClick={() => {

                    }}>
                        {'< Назад'}
                    </MidHeading>

                    <TopRightPlaceholder>
                        <BlueButton onClick={() => {
                            CommonHelper.linkTo(`/online/${id}`, true);
                        }}>
                            УЧЕБНАЯ КОМНАТА
                        </BlueButton>
                    </TopRightPlaceholder>


                </TopNavPlaceholder>

                <InfoBox>
                    <InfoBoxField>
                        <Circle>
                            <img src={require('../../../assets/images/people.svg')} style={{width: 31}}/>
                        </Circle>
                        <GroupName>
                            {teacherGroup.name}
                        </GroupName>
                    </InfoBoxField>
                    <InfoBoxField>
                        <img src={require('../../../assets/images/last_task_img.svg')}
                             style={{width: 20, height: 22, marginRight: 10}}/>
                        <GroupInfoSpan style={{marginRight: 10}}>
                            Занятие:
                        </GroupInfoSpan>
                        <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                            {moment(lesson.startTimestamp).format('DD.MM.YYYY, HH:mm')}
                        </GroupInfoSpan>
                    </InfoBoxField>
                    <InfoBoxField>
                        <img src={require('../../../assets/images/money_bag.svg')}
                             style={{width: 20, height: 22, marginRight: 10}}/>
                        <GroupInfoSpan style={{marginRight: 10}}>
                            Стоимость:
                        </GroupInfoSpan>
                        <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>

                        </GroupInfoSpan>
                    </InfoBoxField>

                </InfoBox>

                <MidPlaceholder>
                    <MidHeading>
                        Учебные материалы (Домашняя работа)
                    </MidHeading>

                    <RedButton onClick={() => {
                        setTasksModalVisible(true);
                    }}>
                        ПРИКРЕПИТЬ
                    </RedButton>

                </MidPlaceholder>

                <TopTableHeading>
                    <NameCol>
                        Название
                    </NameCol>
                    <FromCol>
                        Откуда
                    </FromCol>
                    <LevelCol>
                        Уровень
                    </LevelCol>
                    <OptionsCol>
                        Опции
                    </OptionsCol>
                </TopTableHeading>

                {units.map((st, k) => {

                    return (
                        <StudentRow key={`${st.id}_${k}`}>
                            <NameCol>
                                {st.name}
                            </NameCol>
                            <FromCol>
                                {st.courseName}
                            </FromCol>
                            <LevelCol>
                                {st.level}
                            </LevelCol>
                            <OptionsCol>
                                <TrashImg
                                    src={require('../../../assets/images/eye.svg')}
                                    onClick={() => {
                                        setSelectedUnitId(st.id);
                                    }}
                                />
                            </OptionsCol>
                        </StudentRow>
                    )
                })}

                {exercises.map((st, k) => {

                    return (
                        <StudentRow key={`${st.id}_${k}`}>
                            <NameCol>
                            <span>
                            <span style={{fontWeight: 'bold', marginRight: 4}}>
                                Упражнение
                            </span>
                                {`${st.name}`}
                            </span>
                            </NameCol>
                            <FromCol>
                                {st.courseName}
                            </FromCol>
                            <LevelCol>
                                {st.level}
                            </LevelCol>
                            <OptionsCol>
                                <TrashImg
                                    src={require('../../../assets/images/eye.svg')}
                                    onClick={() => {
                                        setSelectedExerciseId(st.id);
                                    }}
                                />
                            </OptionsCol>
                        </StudentRow>
                    )
                })}

                <div style={{marginTop: 30, marginBottom: 30}} key={`z${updT}`} >
                    <MidHeading style={{marginBottom: 20}}>
                        Успеваемость
                    </MidHeading>

                    <LessonExercisesResultsPanel id={id}/>

                </div>

                <div style={{marginTop: 30, marginBottom: 30}}>
                    <MidHeading style={{marginBottom: 20}}>
                        Посещаемость
                    </MidHeading>

                    <LessonAttendancePanel id={id}/>

                </div>

                <div style={{marginTop: 30, marginBottom: 30}}>
                    <MidHeading style={{marginBottom: 20}}>
                        Способ связи
                    </MidHeading>
                    <CommunicationToolSwitcher {...lesson} onChange={async newTool => {
                        await dispatch(lessonsActions.updateLesson({
                            id: lesson.id,
                            communicationTool: newTool
                        }))
                    }}/>

                </div>

            </WrapperInner>

            {(viewExercise == undefined || isPreviewingExercise == false) ? null :
                <NiceModalWithHeading
                    width={920}
                    zIndex={10000}
                    heading={`${viewExercise.name}`}
                    subHeading={``}
                    onClose={() => {
                        setIsPreviewingExercise(false);
                    }}>

                    <SelectedViewInner>

                        <PgnViewerWrapper
                            boardWidthFraction={0.5}
                            pgn={viewExercise.pgn}>
                            <Chessboard/>
                        </PgnViewerWrapper>

                    </SelectedViewInner>


                </NiceModalWithHeading>
            }


            {tasksModalVisible == false ? null :
                <BrutalOverlayModal onClose={() => {
                    setTasksModalVisible(false);
                }}>

                    <InnerFPlaceholder>

                        <IraEditLessonContentPanel
                            id={id}
                            onUpdated={() => {
                                setUpdT(+new Date());
                            }}
                        />

                    </InnerFPlaceholder>

                </BrutalOverlayModal>
            }

            {viewUnit == undefined ? null :
                <NiceModalWithHeading
                    width={940}
                    heading={viewUnit.name}
                    subHeading={`Курс "${viewUnit.courseName}"`}
                    onClose={() => {
                        setSelectedUnitId(undefined);
                    }}>

                    <SelectedViewInner>
                        <UnitView items={viewUnit.items} canEdit={false}/>
                    </SelectedViewInner>

                </NiceModalWithHeading>
            }


            {viewExercise == undefined ? null :
                <NiceModalWithHeading
                    width={940}
                    heading={(
                        <div style={{cursor: 'pointer'}} onClick={() => {
                            setIsPreviewingExercise(true);
                        }
                        }>
                            {viewExercise.name}
                        </div>
                    )}
                    subHeading={``}
                    onClose={() => {
                        setSelectedExerciseId(undefined);
                    }}>

                    <SelectedViewInner>

                        {viewExercise.isIllegal ?
                            <IllegalWorkspace {...viewExercise}
                                              boardWidthFraction={0.4}
                                              onExerciseFinish={() => {

                                              }}
                                              onStepLimitReached={() => {

                                              }}
                                              onSubExerciseStart={() => {

                                              }}
                                              onTimeFinished={() => {

                                              }}/>
                            :
                            <Workspace {...viewExercise}
                                       allowHandleKeyEvents={() => {

                                       }}
                                       boardWidthFraction={0.4}
                                       onSubExerciseStart={() => {

                                       }}
                                       onSubExerciseEnd={() => {

                                       }}
                                       onExerciseFinish={() => {

                                       }}
                                       onTimeFinished={() => {

                                       }}
                                       onTimerTick={() => {

                                       }}/>}

                    </SelectedViewInner>

                </NiceModalWithHeading>
            }


            {/*{JSON.stringify(lesson)}*/}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const WrapperInner = styled.div`
    padding-top: 31px;
    padding-left: 65px;
    padding-right: 65px;
    box-sizing: border-box;
    @media(max-width: 1300px){
      padding-left: 15px;
      padding-right: 15px;
    }
`;


const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;


const SelectedViewInner = styled.div`
    width: 100%;
    max-height: calc(100vh - 200px);
    overflow-y: auto;
`;

const NameCol = styled.div`
    width: 350px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const OptionsCol = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;

const LevelCol = styled.div`
    width: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`;


const TopTableHeading = styled.div`
    background: #FFA71B;
    border-radius: 4px;
    height: 44px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #202224;
    box-sizing: border-box;
    padding-right: 31px;
`;

const StudentRow = styled.div`
    padding-top: 27px;
    padding-bottom: 27px;
    border-bottom: 0.4px solid rgba(81, 139, 219, 0.4);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 31px;
    box-sizing: border-box;
    background: ${props => (props.selected != true ? 'white' : 'linear-gradient(0deg, #EDF8FF, #EDF8FF), #FFFFFF;')};
`;

const FromCol = styled.div`
    width: 300px;
    box-sizing: border-box;
    padding-left: 31px;
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRightPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const InnerFPlaceholder = styled.div`
    width: 1020px;
    margin: 0 auto;
    @media(max-width: 1020px){
      
    }
`;


const TopNavPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 43px;
`;


const MidPlaceholder = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 21px;
`;

const MidHeading = styled.div`
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    letter-spacing: 0.166667px;
    color: #333539;
`;

const GroupInfoSpan = styled.span`
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.5);
`;


const InfoBox = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 54px;
`;

const InfoBoxField = styled.div`
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const GroupName = styled.div`
    font-size: 19px;
    line-height: 23px;
    color: #1F2041;
    font-weight: bold;
`;

const Circle = styled.div`
    background: #A36EFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
    border-radius: 32px;
    width: 64px;
    height: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 21px;
`;


const LessonItem = styled.div`
    box-sizing: border-box;
    margin-bottom: 10px;
    padding: 5px;
    background-color: white;
    border-radius: 4px;
    border: 1px solid whitesmoke;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;

const Name = styled.div`
    
`;

const TrashImg = styled.img`
    width: 24px;
    height: 24px;
    cursor: pointer;
    :hover{
      opacity: 0.8;
    }
`;
