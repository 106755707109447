import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import IraTeacherTemplate from "../templates/IraTeacherTemplate";
import IraUserHomeworkLessonsPanel from "../lessons/panels/IraUserHomeworkLessonsPanel";
import SmartExercisesAndFoldersPanel from "../smart_exercises/panels/SmartExercisesAndFoldersPanel";

const TABS = [
    {
        label: 'Домашняя работа',
        value: 'my_exercises'
    },
    // {
    //     label: 'Все упражнения',
    //     value: 'all_exercises',
    // },
    // {
    //     label: 'Все курсы',
    //     value: 'all_modules',
    // }

]

export default function UserHomeworkApp(props) {
    let {mode} = props.match.params;
    const [tab, setTab] = useState(mode == undefined ? 'my_exercises' : mode);
    const [balance, setBalance] = useState(undefined);

    return (
        <IraTeacherTemplate active={'homework'}>

            <HorHeading>
                <TopLeft>
                    {TABS.map((a, i) => {
                        return (
                            <TabItem selected={(tab == a.value)} onClick={() => {
                                setTab(a.value);
                            }}>
                                {a.label}
                            </TabItem>
                        )
                    })}
                </TopLeft>
                <TopRight>

                </TopRight>
            </HorHeading>

            <Wrapper>

                {tab != 'my_exercises' ? null :
                    <IraUserHomeworkLessonsPanel/>
                }

                {tab != 'all_exercises' ? null :
                    <SmartExercisesAndFoldersPanel/>
                }



            </Wrapper>

        </IraTeacherTemplate>
    );
}

const Wrapper = styled.div`
    padding: 40px;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow-y: auto;
`;

const topHeight = 70;

const HorHeading = styled.div`
    width: 100%;
    height: ${topHeight}px;
    background: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
`;

const TopLeft = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const TopRight = styled.div`
    
`;

const TabItem = styled.div`
    font-size: 16px;
    line-height: 24px;
    color: ${props => (props.selected ? '#085BFF' : '#8593A8')};
    font-weight: ${props => (props.selected ? 'bold' : 'normal')};
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    margin-right: 40px;
    :hover{
      color: #085BFF;
    }
`;

const Heading = styled.div`
    font-size: 24px;
    line-height: 26px;
    color: #333539;
    margin-bottom: 40px;
`;

const SubHeading = styled.div`
    font-size: 14px;
    line-height: 17px;
    color: #1F2041;
    margin-bottom: 25px;
`;

const Content = styled.div`
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 30px;
    background: white;
`;

