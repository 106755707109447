import React, {useState, useEffect, useRef, useCallback, useReducer} from 'react';
import styled from 'styled-components';
import SimpleRealtimeBoard from "../realtime/board/SimpleRealtimeBoard";

export default function HeadlessLessonApp(props) {
    let {id} = props.match.params;
    let jitsiUrl = `https://jitsi.shagi.online/room_lesson_${id}`;
    let roomId = `lesson_${id}`;

    return (
        <Wrapper>
            <BoardPlaceholder>
                <SimpleRealtimeBoard
                    isIllegal={false}
                    adminMode={false}
                    boardId={`${roomId}`}
                    participants={[]}
                    orientation={'white'}
                    onBoardStateChange={() => {
                    }}
                    onReady={d => {
                    }}
                />
            </BoardPlaceholder>
            <JitsiPlaceholder>

                <iframe allow="camera; microphone; display-capture; autoplay"
                        src={jitsiUrl}
                        name="jitsiConferenceFrame0" id="jitsiConferenceFrame0" allowFullScreen="true"
                        style={{height: '100%', width: '100%', border: '0px'}}></iframe>

                {/*<LessonCommutePanel id={id}*/}
                {/*                    allowGuests={true}*/}
                {/*/>*/}
            </JitsiPlaceholder>
        </Wrapper>
    );
}

const Wrapper = styled.div`
  width: 900px;
  height: 500px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const BoardPlaceholder = styled.div`
  width: 500px;
  height: 500px;
`;

const JitsiPlaceholder = styled.div`
  width: 400px;
  height: 500px;
  box-sizing: border-box;
`;