import React, {useState} from 'react';
import styled from 'styled-components';
import {v4 as uuid4} from 'uuid';
import {SOLUTIONS_TYPES} from '../const';
import SolutionEditor, {newVariant} from './SolutionEditor';

import ls from 'local-storage'

export const newSolution = (fen, uid) => {
    return {
        id: uuid4(),
        duration: 0,
        rating: !ls('editor-new-sub-solution') ? 400 : ls('editor-new-sub-solution'),
        name: '',
        description: '',
        tags: [],
        variants: [newVariant(fen, uid)]
    };
}

// const getFenImageUrl = (fen) => fen ? `http://www.fen-to-image.com/image/36/${fen.split(' ')[0]}` : fen;
const getFenImageUrl = (fen) => fen ? `https://api.cloud1.shagi.online/ch/image/36/${fen.split(' ')[0]}` : fen;

export default function Solution(props) {
    let {
        index = 1,
        fen = '',
        node,
        solution = {},
        onDelete = e => e,
        onSave = solution => solution,
        onSubExerciseSelect = solution => solution,
        getImageUrlFen = variants => getFenImageUrl((variants.find(variant => variant.type === SOLUTIONS_TYPES.WIN) || {}).fen)
    } = props;

    const [isSelected, setIsSelected] = useState(false);

    const deselect = () => setIsSelected(false);
    const select = () => {
        setIsSelected(true);
        onSubExerciseSelect(solution)
    };
    const save = solution => {
        deselect();
        onSave(solution);
    }

    return (
        <Wrapper>
            <Content key={solution.id} selected={isSelected}>
                <Header index={index} name={solution.name} selected={isSelected} onClose={deselect}
                        onNameClick={select}/>
                <Body selected={isSelected} onClick={select}>
                {
                    isSelected ?
                        <EditorPlaceholder>
                            <SolutionEditor fen={fen}
                                            node={node}
                                            solution={solution}
                                            onSave={save}
                                            onDelete={onDelete}/>
                        </EditorPlaceholder>
                        :
                        <View>
                            <BoardIcon src={getImageUrlFen(solution.variants)}/>
                        </View>
                }
                </Body>
            </Content>
        </Wrapper>
    );
}

function Header({index, name, selected, onClose, onNameClick}) {
    const Header = styled.div`
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    `;
    const Name = styled.div`
        font-weight: bold;
        font-size: 14px;
        line-height: 18px;
        opacity: 0.7;
        flex: 1;
        max-width: ${(selected ? '300px' : `160px`)}; // dirty
        box-sizing: border-box;
        padding-right: 5px;
    `;
    const Closer = styled.div`
        cursor: pointer;
        :hover{
          opacity: 0.8;
        }
    `;

    return (
        <Header>
            <Name onClick={onNameClick}>{`${ index }. ${name}`}</Name>
            {selected ? <Closer onClick={onClose}>{'x'}</Closer> : null}
        </Header>
    );
}

const Wrapper = styled.div`
    
`;

const EditorPlaceholder = styled.div`
    min-width: 320px;
`;

const Content = styled.div`
    box-sizing: border-box;
    margin-bottom: 10px;
    cursor: ${props => (props.selected ? 'default' : 'pointer')};
    width: 100%;
    border: ${props => (props.selected ? '1px solid #293742' : '2px solid whitesmoke')};
    border-radius: 4px;
    padding: 4px;
    :hover{
      opacity: ${props => (props.selected ? 1 : 0.7)};
    }
`;

const Body = styled.div`
    height: auto;
`;

const View = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const BoardIcon = styled.img`
    width: 120px;
    height: 120px;
    :hover{
      opacity: 0.75;
    }
`;
