import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'
import * as actions from "../../../redux/actions/TagsActions";
import UserRatingPanel from "./UserRatingPanel";
import {getSortedItems} from "../../lessons/panels/UserCopeLessonPanel";

export default function ExerciseTagsUserRatingPanel(props) {
    const {
        id
    } = props;

    const {tags, loading} = useMappedState(useCallback(state => {
        return {
            tags: state.tags.tagsMap.toArray().sort((a, b) => (+b.timestamp - +a.timestamp)).filter(x => (x.isMain == true && x.type == 'exercise')),
            loading: state.tags.loading
        }
    }, []));
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(actions.loadAllTags());
    }, [id]);

    let sortedTags = getSortedItems(tags);

    return (
        <Wrapper>

            <div style={{marginBottom: 30, paddingBottom: 20, borderBottom: '1px solid lightgrey'}}>

                <h3>
                    Рейтинг
                </h3>

                <UserRatingPanel id={id} hasUserUpdateInfoTool={true}/>

            </div>

            <TagsList>
                {sortedTags.map((tag, i) => {
                    return (
                        <TagItem key={tag.id}>
                            <h3>
                                {`${tag.name}`.slice(5)}
                            </h3>
                            <UserRatingPanel id={id} tagName={tag.name}/>
                        </TagItem>
                    )
                })}
            </TagsList>

        </Wrapper>
    );
}

const TagItem = styled.div`
  margin-bottom: 10px;
`;

const Wrapper = styled.div`
  max-height: 80vh;
  overflow-y: auto;
`;

const TagsList = styled.div`
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid lightgrey;

  :first-of-type {
    border-bottom: none;
  }
`;