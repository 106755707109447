import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import {Map, Set} from 'immutable';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import Sidebar from 'arui-feather/sidebar'
import Spin from 'arui-feather/spin'
import axios from 'axios'

import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import {Button} from "../../ui/Button";

import Select from 'react-select'

const VIEW_OPTIONS = [
    {
        label: 'Да',
        value: true
    },
    {
        label: 'Нет',
        value: false
    }
]

export default function UpdateUnitInfoForm(props) {
    const {
        loading = false, onSave = d => {

        },
        canDelete = false,
        onDelete = () => {

        },
        canEdit = false
    } = props;

    const [name, setName] = useState((props.name == undefined) ? '' : props.name);
    const [description, setDescription] = useState((props.description == undefined) ? '' : props.description);
    const [minRating, setMinRating] = useState(props.minRating == undefined ? '1000' : props.minRating);
    const [maxRating, setMaxRating] = useState(props.maxRating == undefined ? '3000' : props.maxRating);
    const [minAge, setMinAge] = useState(props.minAge == undefined ? '6' : props.minAge);
    const [maxAge, setMaxAge] = useState(props.maxAge == undefined ? '100' : props.maxAge);
    const [canViewIfNotPaid, setCanViewIfNotPaid] = useState(props.canViewIfNotPaid == undefined ? true : props.canViewIfNotPaid);


    return (
        <Wrapper>

            <Field>
                <Label>
                    Название
                </Label>
                <Input value={name} onChange={evt => {
                    setName(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Описание
                </Label>
                <Textarea value={description} onChange={evt => {
                    setDescription(evt.target.value);
                }}/>

            </Field>

            <Field>
                <Label>
                    Доступен, если не оплачен
                </Label>
                <Select options={VIEW_OPTIONS} value={VIEW_OPTIONS.filter(a => (a.value == canViewIfNotPaid))}
                        onChange={a => {
                            setCanViewIfNotPaid(a.value);
                        }}/>

            </Field>

            <Field>
                <Label>
                    Минимальный рекомендуемый возраст
                </Label>
                <Input value={minAge} onChange={evt => {
                    setMinAge(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Максимальный рекомендуемый возраст
                </Label>
                <Input value={maxAge} onChange={evt => {
                    setMaxAge(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Минимальный рекомендуемый рейтинг
                </Label>
                <Input value={minRating} onChange={evt => {
                    setMinRating(evt.target.value);
                }}/>
            </Field>

            <Field>
                <Label>
                    Максимальный рекомендуемый рейтинг
                </Label>
                <Input value={maxRating} onChange={evt => {
                    setMaxRating(evt.target.value);
                }}/>
            </Field>

            {canEdit == false ? null :
                <Field>
                    <Button onClick={() => {
                        if (loading == true) {
                            return;
                        }
                        onSave({
                            name,
                            description,
                            minAge: +minAge,
                            minRating: +minRating,
                            maxAge: +maxAge,
                            maxRating: +maxRating,
                            canViewIfNotPaid: canViewIfNotPaid
                        });
                    }}>
                        <Spin visible={loading}/>
                        {loading == true ? null :
                            <span>
                            Сохранить
                        </span>
                        }
                    </Button>
                </Field>
            }


            {canDelete == false || canEdit == false ? null :
                <Field>
                    <div>
                        <span onClick={() => {
                            if (window.confirm('Вы уверены?') == false) {
                                return;
                            }
                            onDelete();
                        }}>
                            удалить
                        </span>
                    </div>
                </Field>
            }

        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
`;
