import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'

import {Map, Set} from 'immutable'

import {Button} from "../../ui/Button";
import {Input} from "../../ui/Input";
import {Textarea} from "../../ui/Textarea";

import Spin from 'arui-feather/spin'

import DatePicker from 'react-datepicker'

import Tabs, {TabItem} from '../../ui/Tabs'
import AllExercisesPanel from "../../exercises/panels/AllExercisesPanel";
import AllExercisesFoldersPanel from "../../exercises_folders/panels/AllExercisesFoldersPanel";
import LessonTasksPanel from "../panels/LessonTasksPanel";
import CoursesUnitsSelectorPanel from "../../courses/panels/CoursesUnitsSelectorPanel";
import LessonUnitsPanel from "../panels/LessonUnitsPanel";
import TestsPicker from "../../tests/tools/TestsPicker";
import ManyTestsPlayPanel from "../view/ManyTestsPlayPanel";
import LessonTestsPanel from "../panels/LessonTestsPanel";

export default function UpdateLessonForm(props) {
    let {id} = props;
    const [expandedFolderId, setExpandedFolderId] = useState(undefined);
    const {
        loading,
        startTimestamp = +new Date() + 86400000,
        onSave = newData => {

        },
        name = '',
        description = '',
        exercisesIds = [],
        unitsIds = [],
        testsIds = [],
        performanceContent = null
    } = props;
    const [formData, setFormData] = useState({
        name: name,
        description: description,
        startTimestamp: startTimestamp,
        exercisesIds: exercisesIds,
        unitsIds: unitsIds,
        testsIds: testsIds
    });

    return (
        <Wrapper>

            <Tabs tabs={[{
                label: 'Информация',
                content: (
                    <TabItem>
                        <Field>
                            <Label>
                                Название
                            </Label>
                            <Input value={formData.name} onChange={evt => {
                                let s = evt.target.value;
                                setFormData({
                                    ...formData,
                                    name: s
                                });
                            }}/>
                        </Field>

                        <Field>
                            <Label>
                                Описание
                            </Label>
                            <Textarea value={formData.description} onChange={evt => {
                                let s = evt.target.value;
                                setFormData({
                                    ...formData,
                                    description: s
                                });
                            }}/>
                        </Field>

                        <Field>
                            <Label>
                                Время занятия
                            </Label>
                            <DatePicker
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                dateFormat="d MMMM, yyyy HH:mm"
                                timeCaption="Время"
                                selected={new Date(formData.startTimestamp)}
                                onChange={newDate => {
                                    setFormData({
                                        ...formData,
                                        startTimestamp: +new Date(newDate)
                                    });
                                }}
                            />

                        </Field>

                        <Field>
                            <Button onClick={() => {
                                onSave(formData);
                            }}>
                                <Spin visible={loading}/>
                                {loading == true ? null :
                                    <span>
                                        Сохранить
                                    </span>
                                }
                            </Button>
                        </Field>

                    </TabItem>
                )
            },

                {
                    label: 'Модули',
                    content: (
                        <TabItem>

                            <Tabs tabs={[
                                {
                                    label: 'Редактирование',
                                    content: (
                                        <TabItem>
                                            <div style={{marginBottom: 5}}>
                                                {`Выбрано: ${formData.unitsIds.length}`}
                                            </div>

                                            <CoursesUnitsSelectorPanel
                                                selectedUnitsIds={formData.unitsIds}
                                                onChange={arr => {
                                                    setFormData({
                                                        ...formData,
                                                        unitsIds: arr
                                                    });
                                                    onSave({unitsIds: arr});
                                                }}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Просмотр',
                                    content: (
                                        <TabItem>
                                            <LessonUnitsPanel id={id}/>
                                        </TabItem>
                                    )
                                }
                            ]}/>

                        </TabItem>
                    )
                },
                {
                    label: 'Тесты',
                    content: (
                        <TabItem>

                            <Tabs tabs={[
                                {
                                    label: 'Редактирование',
                                    content: (
                                        <TabItem>
                                            <div style={{marginBottom: 5}}>
                                                {`Выбрано: ${formData.testsIds.length}`}
                                            </div>

                                            <TestsPicker
                                                testsIds={formData.testsIds}
                                                onChange={arr => {
                                                    setFormData({
                                                        ...formData,
                                                        testsIds: arr
                                                    });
                                                    onSave({testsIds: arr});
                                                }}/>
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Просмотр',
                                    content: (
                                        <TabItem>
                                            <LessonTestsPanel id={id} />
                                        </TabItem>
                                    )
                                }
                            ]}/>

                        </TabItem>
                    )
                },

                {
                    label: 'Упражнения',
                    content: (
                        <TabItem>

                            <Tabs tabs={[
                                {
                                    label: 'Редактирование',
                                    content: (
                                        <TabItem>
                                            <div style={{marginBottom: 5}}>
                                                {`Выбрано: ${formData.exercisesIds.length}`}
                                            </div>

                                            <AllExercisesFoldersPanel
                                                expandId={expandedFolderId}
                                                onFolderSelect={id => {
                                                    setExpandedFolderId(id);
                                                }}
                                                canEdit={false}
                                                openExerciseOnClick={false} canAddExercise={false}
                                                selectedExercisesIds={formData.exercisesIds}
                                                onExerciseClick={exId => {
                                                    console.log('onExerciseClick: exId = ', exId);
                                                    let newExercisesIds = (formData.exercisesIds.indexOf(exId) > -1) ? formData.exercisesIds.filter(eId => (eId != exId)) : formData.exercisesIds.concat([exId]);
                                                    setFormData({
                                                        ...formData,
                                                        exercisesIds: newExercisesIds
                                                    });
                                                    onSave({exercisesIds: newExercisesIds});
                                                }}

                                            />
                                        </TabItem>
                                    )
                                },
                                {
                                    label: 'Просмотр',
                                    content: (
                                        <TabItem>
                                            <LessonTasksPanel id={id}/>
                                        </TabItem>
                                    )
                                }
                            ]}/>


                        </TabItem>
                    )
                },

                {
                    label: 'Успеваемость',
                    content: (
                        <TabItem>
                            {performanceContent}
                        </TabItem>
                    )
                }

            ]}/>


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const Field = styled.div`
    margin-bottom: 10px;
`;

const Label = styled.div`
    font-weight: bold;
    margin-bottom: 5px;
`;

const CalendarPlaceholder = styled.div`
    height: 300px;
`;
