import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import {useDispatch, useMappedState} from 'redux-react-hook'
import moment from 'moment';
import uuid from 'uuid';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import {FACELESS_AVATAR} from "../../../constants/config";

import * as actions from '../../../redux/actions/TeacherGroupsActions'

import {Button} from "../../ui/Button";

import Sidebar from 'arui-feather/sidebar'
import UpdateTeacherGroupForm from "../forms/UpdateTeacherGroupForm";
import CommonHelper from "../../../helpers/CommonHelper";

import Tabs, {TabItem} from '../../ui/Tabs'
import UpdateTeacherGroupMembershipPanel from "./UpdateTeacherGroupMembershipPanel";
import FacesListTool from "../tools/FacesListTool";
import NiceModal from "../../modals/NiceModal";

export default function TeacherGroupsPanel(props) {
    let {teacherId} = props;
    const [addModalVisible, setAddModalVisible] = useState(false);
    const [selectedGroupId, setSelectedGroupId] = useState(undefined);
    const dispatch = useDispatch();
    let {currentUser, loading, currentUserId, teacherGroups, getUsersByIds} = useMappedState(useCallback(state => {
        let currentUser = state.users.usersMap.get(state.users.currentUserId);
        let teaId = (teacherId == undefined) ? currentUserId : teacherId;
        let teacherGroups = state.teacherGroups
            .teacherGroupsMap.toArray()
            .filter(g => (g.teacherId == teaId))
            .filter(a => (a != undefined && a.deleted != true))
            .sort((a, b) => (+b.timestamp - +a.timestamp));
        let getUsersByIds = ids => ids.map(a => state.users.usersMap.get(a)).filter(a => (a != undefined));
        return {
            currentUser: state.users.usersMap.get(state.users.currentUserId),
            loading: state.users.loading || state.teacherGroups.loading,
            currentUserId: state.users.currentUserId,
            teacherGroups: teacherGroups,
            getUsersByIds: getUsersByIds
        }
    }, []));
    let teaId = (teacherId == undefined) ? currentUserId : teacherId;

    useEffect(() => {
        if (teaId != undefined) {
            dispatch(actions.loadAllTeacherGroupsOfTeacher(teaId))
        }
    }, [teaId]);

    if (loading == true && teacherGroups.length == 0) {
        return (
            <Code/>
        )
    }

    let selectedGroup = (selectedGroupId == undefined) ? undefined : teacherGroups.filter(a => (a.id == selectedGroupId))[0];

    return (
        <Wrapper>

            <TopPlaceholder>
                <TopHeading>
                    Ваши группы
                </TopHeading>
                <CreateGroupButton onClick={() => {
                    setAddModalVisible(true);
                }}>
                    Добавить группу
                </CreateGroupButton>
            </TopPlaceholder>

            <GroupsList>
                {teacherGroups.map((g, k) => {
                    let {name, code, studentsIds, minRating, maxRating} = g;
                    let students = getUsersByIds(studentsIds == undefined ? [] : studentsIds);
                    let latestLessonText = `Не создано`; // todo: implement
                    let groupLevelText = `${minRating == undefined ? '' : minRating} - ${maxRating == undefined ? '' : maxRating}`;
                    return (
                        <Box key={g.id} onClick={() => {
                            CommonHelper.linkTo(`/group/${g.id}`);
                        }}>
                            <BoxTop>
                                <BoxGroupImagePlaceholder>
                                    <PeopleImg src={require('../../../assets/images/people.svg')}/>
                                </BoxGroupImagePlaceholder>
                                <BoxTopName>
                                    {name}
                                </BoxTopName>
                            </BoxTop>

                            <FacesPlaceholder>
                                {students.length == 0 ?
                                    <VoidStudentsPlaceholder>
                                        Нет участников
                                    </VoidStudentsPlaceholder>
                                    :
                                    <FacesListTool faces={students}/>
                                }
                            </FacesPlaceholder>

                            <GroupInfoPlaceholder>

                                <GroupInfoRow>
                                    <img src={require('../../../assets/images/last_task_img.svg')}
                                         style={{width: 20, height: 22, marginRight: 10}}/>
                                    <GroupInfoSpan style={{marginRight: 10}}>
                                        Занятие:
                                    </GroupInfoSpan>
                                    <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                                        {latestLessonText}
                                    </GroupInfoSpan>
                                </GroupInfoRow>

                                <GroupInfoRow>
                                    <img src={require('../../../assets/images/group_level_img.svg')}
                                         style={{width: 21, height: 27, marginRight: 10}}/>
                                    <GroupInfoSpan style={{marginRight: 10}}>
                                        Уровень:
                                    </GroupInfoSpan>
                                    <GroupInfoSpan style={{color: 'rgba(31, 32, 65, 0.9)'}}>
                                        {groupLevelText}
                                    </GroupInfoSpan>
                                </GroupInfoRow>

                            </GroupInfoPlaceholder>

                        </Box>

                    )
                })}
            </GroupsList>


            {addModalVisible == false ? null :
                <NiceModal onClose={() => {
                    setAddModalVisible(false);
                }}>

                    <AddInner>

                        <AddInnerHeader>
                            Создать группу
                        </AddInnerHeader>

                        <UpdateTeacherGroupForm loading={loading} onSave={data => {
                            data.teacherId = teaId;
                            dispatch(actions.createTeacherGroup(data)).then(pld => {
                                setAddModalVisible(false);
                            })
                        }}/>
                    </AddInner>

                </NiceModal>
            }


        </Wrapper>
    );
}

const Wrapper = styled.div`
    
`;

const AddInnerHeader = styled.div`
    text-align: center;
    letter-spacing: 0.166667px;
    color: #333539;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 29px;
`;

const AddInner = styled.div`
    width: 540px;
    @media(max-width: 640px){
      width: 100%;
    }
`;

const GroupInfoPlaceholder = styled.div`
    margin-top: 20px;
`;

const GroupInfoRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    :first-of-type{
      margin-bottom: 17px;
    }
    @media(max-width: 1220px){
      font-size: 14px;
    }
`;

const GroupInfoSpan = styled.span`
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.5);
`;

const GroupsList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const VoidStudentsPlaceholder = styled.div`
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.171429px;
    color: rgba(31, 32, 65, 0.7);
`;

const GroupItem = styled.div`
    background: white;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const InfoPlaceholder = styled.div`
    cursor: pointer;
    :hover{
      opacity: 0.5;
    }
`;

const Name = styled.div`
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
`;

const LineStats = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`;

const StatsPlaceholder = styled.div`
    
`;

const CodeSubPlaceholder = styled.div`
    
`;

const CodePlaceholder = styled.div`
    font-size: 12px;
    opacity: 0.5;
`;

const UImg = styled.img`
    height: 16px;
`;

const TopPlaceholder = styled.div`
    margin-bottom: 43px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`;

const TopHeading = styled.div`
    font-size: 24px;
    line-height: 26px;
    letter-spacing: 0.166667px;
    color: #333539;
`;

const CreateGroupButton = styled.div`
    background: #FF624F;
    border-radius: 1000px;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-left: 36px;
    padding-right: 36px;
    height: 44px;
    cursor: pointer;
    
    font-weight: bold;
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    text-transform: uppercase;
    
    :hover{
      opacity: 0.8;
    }
`;

const Box = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(31, 32, 65, 0.05);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 30px;
    width: calc(33.333% - 20px);
    max-width: calc(33.333% - 20px);
    margin-bottom: 30px;
    margin-top: 0px;
    margin-right: 30px;
    
    cursor: pointer;
    
    :nth-of-type(3n){
      margin-right: 0px;
    }
    :hover{
      opacity: 0.8;
    }
`;

const BoxTop = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 18px;
`;

const BoxGroupImagePlaceholder = styled.div`
    background: #A36EFF;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 20px rgba(31, 32, 65, 0.1);
    border-radius: 32px;
    width: 64px;
    min-width: 64px;
    height: 64px;
    margin-right: 21px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const BoxTopName = styled.div`
    font-weight: bold;
    font-size: 19px;
    line-height: 23px;
    color: #1F2041;
`;

const PeopleImg = styled.img`
    width: 31px;
`;

const FacesPlaceholder = styled.div`
    margin-top: 18px;
`;
